import React from 'react'

import { CSSTransition, SwitchTransition} from 'react-transition-group';

import {
  OvalSpinnerSvg,
} from '../../constants/svgs'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
} from '../../constants/requestStates'

import CompletionCheckIcon from './CompletionCheckIcon'

const SendRequestButton = ({
  defaultContent,
  defaultContentText,
  backgroundColor,
  loadingText,
  successText,
  isLightTheme,
  requestState,
  isDisabled,
  marginTop,
  onClick
}) => {
  const buttonBackgroundColor = !!(backgroundColor) ? backgroundColor : (isLightTheme ? "#FFFFFF" : "#000000")
  const buttonBorder = isLightTheme ? "2px #000000 solid" : "none"
  const contentColor = isLightTheme ? "#000000" : "#FFFFFF"

  const isFetching = requestState === REQUEST_FETCHING
  const isSuccess = requestState === REQUEST_SUCCESS

  const loadingContent = loadingText || defaultContent
  const successContent = successText || defaultContent
  return (
    <button
      style={{display: "block", marginTop, width: "100%", fontFamily: "Futura", padding: "12px 15px", color: contentColor, backgroundColor: buttonBackgroundColor, border: buttonBorder}}
      disabled={isDisabled}
      onClick={() => !isDisabled && onClick()}
    >
      <SwitchTransition mode="out-in">
        <CSSTransition
          timeout={450}
          classNames={"hide-fade-in-400"}
          unmountOnExit
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          key={`${requestState}-${defaultContentText}`}
        >
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
            <div style={{display: "inline-block", fontSize: "min(6vw, 25px)", lineHeight: "min(8vw, 30px)"}}>
              {isFetching ? loadingContent : (isSuccess ? successContent : defaultContent)}
            </div>
            {(isSuccess || isFetching) && (
              <span style={{fontSize: "min(6vw, 25px)", width: "0.35em"}} />
            )}
            {isFetching && (
              <OvalSpinnerSvg
                  size={"min(5.5vw, 22px)"}
                  color={contentColor}
                />
            )}
            {isSuccess && (
              <CompletionCheckIcon
                checkColorComplete={buttonBackgroundColor}
                checkboxBaseClassName={isLightTheme ? "checkbox-request-button-black" : "checkbox-request-button"}
                isComplete
                animateCheck
              />
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </button>
  )
}

export default SendRequestButton