import React from 'react'

const SettingsButton = ({
  title,
  icon,
  isDisabled,
  isCopied,
  onClick
}) => {
  return (
    <button
      style={{display: "block", width: "100%", opacity: isCopied ? 0.7 : 1.0, fontFamily: "Futura", padding: "min(4.5vw, 18px)", fontSize: "min(6vw, 25px)", marginTop: "min(4vh, 25px)", color: "#000000", backgroundColor: "#ffffff", textAlign: "center", boxShadow: "0px 1px 1.5px 0.5px #dddddd", border: "none"}}
      disabled={isDisabled}
      onClick={() => onClick()}
    >
      {title.toUpperCase()}
    </button>
  );
}

export default SettingsButton;