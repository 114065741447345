import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  resetLoginPage,
} from '../actions/loginPage';

import {
  toggleBodyScroll,
  i18n,
  hideLoadingScreen,
  initializeGA,
  pageViewGA,
} from '../utils'

import { Redirect } from 'react-router-dom';

import {
  REQ_FETCH_APP_CONFIG,
} from '../constants/requestTypes'

import {
  COMMON_INNER_HEIGHT,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
} from '../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
} from '../constants/formFields'

class RedirectContainer extends Component {
  componentWillMount() {
    window.location = this.props.redirectTo || "/"
  }

  componentDidMount() {

  }
  componentWillUnmount() {
  }

  render() {
    return (
      null
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(RedirectContainer);
