import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import TrainerCertificationDetails from './common/TrainerCertificationDetails'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK
} from '../constants/gaEventLabels'

import agent from '../agent'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionCertificationUrl,
  ProductionCourseUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  CertificationCheckoutLink,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
  getAffiliateCodeFromQueryString,
  identifyCertificationAnalyticsWebUser,
} from '../utils'

import Helmet from './common/Helmet'

class CertificationPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      logoOutlineColor: "#EDEEF2",
      certificationCodeDetailsRequest: {state: REQUEST_UNSTARTED}
    }
  }

  async componentWillMount() {
    this.setState({...this.state, certificationCodeDetailsRequest: {state: REQUEST_FETCHING}})
    try {
      const unique_code = getAffiliateCodeFromQueryString(window.location.href)
      if (!!unique_code) {
        const codeDetailsRequestResult = await agent.NeuroFitBackend.retrieveCertificationUniqueCodeDetails({unique_code})
        this.setState({...this.state, certificationCodeDetailsRequest: {state: REQUEST_SUCCESS, result: codeDetailsRequestResult}})
        await identifyCertificationAnalyticsWebUser({uuid: unique_code})
      }

    } catch (err) {
      this.setState({...this.state, certificationCodeDetailsRequest: {state: REQUEST_ERROR}})
    }
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      i18n,
      isIOSDevice,
      isAndroidDevice,
      isNasmPage
    } = this.props

    const {headerBorderColor, headerBackgroundColor, headerBackgroundBlur, logoOutlineColor, textShadow, certificationCodeDetailsRequest} = this.state
    
    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={isNasmPage ? "Nervous System Course For Fitness Trainers & Gym Owners | NeuroFit" : "Nervous System Certification | Somatic Exercises | NeuroFit"}
          description={isNasmPage ? "A 3-Week Nervous System Course for fitness trainers and gym owners looking to accelerate personal & professional growth." : "A 3-Week Nervous System Certification for coaches & healers, covering nervous system regulation, somatic exercises, and trauma-informed witnessing."}
          href={isNasmPage ? ProductionCourseUrl : ProductionCertificationUrl}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#FFFFFF"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: "min(20px, 4%)", paddingBottom: 12, backgroundColor: headerBackgroundColor, zIndex: 1000, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(5.75vw, 24px)"}
              color={"#000000"}
              dropShadowColor={logoOutlineColor}
              onClick={() => window.location = "/"}
            />
            <div style={{display: "flex", alignItems: "center"}}>
              <span
                onClick={() => {
                  if (isNasmPage) {
                    recordPixelEventIfAvailable("Lead")
                    recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK, appDownloadUrl)
                  } else {
                    window.location = "/"
                  }
                }}
                style={{cursor: "pointer", fontFamily: "Futura Medium", textShadow, fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
              >
                {isNasmPage ? "GET THE APP" : "Home"}
              </span>
            </div>
          </div>
          <TrainerCertificationDetails
            innerHeight={innerHeight}
            safeAreaInsets={safeAreaInsets}
            isNative={false}
            isNasmPage={isNasmPage}
            onContainerScroll={updatedState => {
              this.setState({...updatedState})
            }}
            certificationCodeDetailsRequest={certificationCodeDetailsRequest}
          />
        </div>
      </div>
    )
  }
}

export default CertificationPage;