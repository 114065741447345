import {
  baseDomain,
  baseAppDomain,
  baseDashboardDomain,
  getCsrfTokenFromCookies,
  getDashboardCsrfTokenFromCookies,
  DefaultCdnBaseUrl,
  DefaultQuizResultCdnBaseUrl,
  DefaultCertificationCodesCdnBaseUrl,
} from './utils'

import axios from 'axios'

import {
  REQUEST_SUCCESS
} from './constants/requestStates'

const promiseRetry = require('promise-retry');


const CSRF_TOKEN_HEADER = 'X-Csrf-Token'

// Resolves iOS Cookie 7-Day Expiration by hosting API on same domain as App.
// This is a fix for WebKit labeling cross-site cookies from our backend as 3rd party CNAME Cloaking.
const APP_BACKEND_BASE_URL = (process.env.NODE_ENV === 'production') ?
  `https://${baseAppDomain}/api`
  : 'http://localhost:5001/api';

const HRV_API_BACKEND_BASE_URL = (process.env.NODE_ENV === 'production') ?
  `https://hrv-api.${baseDomain}`
  : 'http://localhost:8002';

const DASHBOARD_APP_BACKEND_BASE_URL = (process.env.NODE_ENV === 'production') ?
  `https://${baseDashboardDomain}/api`
  : 'http://localhost:5005/api';

const requests = {
  get: (fullPath, options={retries:2}) => {
    return promiseRetry((retry, number) => {
      return axios.request({
        method: 'GET',
        url: fullPath,
        responseType: 'json',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers
        },
        xsrfHeaderName: CSRF_TOKEN_HEADER
      })
        .then(result => {
          return result.data
        })
        .catch((err) => {
          if (err.response && err.response.status && err.response.status >= 500 && number <= options.retries) {
            retry(err);
          } else if (err.response && err.response.data) {
            throw err.response.data;
          } else {
            throw {error_code: "bad_request_error", "error_message": "Something went wrong - please try again."}
          }
        });
    });
  },
  post: (fullPath, body, options={retries:2}) => {
    return promiseRetry((retry, number) => {
      return axios.request({
        method: 'POST',
        url: fullPath,
        data: body,
        responseType: 'json',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers
        },
        xsrfHeaderName: CSRF_TOKEN_HEADER
      })
        .then(result => {
          return result.data
        })
        .catch((err) => {
          if (err.response && err.response.status && err.response.status >= 500 && number <= options.retries) {
            retry(err);
          } else if (err.response && err.response.data) {
            throw err.response.data;
          } else {
            throw {error_code: "bad_request_error", "error_message": "Something went wrong - please try again."}
          }
        });
    });
  },
  put: (fullPath, body, options={retries:2}) => {
    return promiseRetry((retry, number) => {
      return axios.request({
        method: 'PUT',
        url: fullPath,
        data: body,
        responseType: 'json',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers
        },
        xsrfHeaderName: CSRF_TOKEN_HEADER
      })
        .then(result => {
          return result.data
        })
        .catch((err) => {
          if (err.response && err.response.status && err.response.status >= 500 && number <= options.retries) {
            retry(err);
          } else if (err.response && err.response.data) {
            throw err.response.data;
          } else {
            throw {error_code: "bad_request_error", "error_message": "Something went wrong - please try again."}
          }
        });
    });
  },
  postFormJsonFile: (baseUrl, fields, fileContentsJson, options={retries:2}) => {
    const formData = new FormData();
    formData.append('Content-Type', 'application/json');
    Object.entries(fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    const fileData = JSON.stringify(fileContentsJson)
    const fileObject = new Blob([fileData], {type : 'application/json'});
    formData.append('file', fileObject);

    return promiseRetry((retry, number) => {
      return axios.request({
        method: 'POST',
        url: baseUrl,
        data: formData,
        responseType: 'json',
        headers: {
          ...options.headers
        }
      })
        .then(result => {
          return result.data
        })
        .catch((err) => {
          if (err.response && err.response.status && err.response.status >= 500 && number <= options.retries) {
            retry(err);
          } else if (err.response && err.response.data) {
            throw err.response.data;
          } else {
            throw {error_code: "bad_request_error", "error_message": "Something went wrong - please try again."}
          }
        });
    });
  },
  putFile: (fullPath, file, options={retries:2}) => {
    return promiseRetry((retry, number) => {
      return axios.put(
        fullPath,
        file,
        {
          headers: {
            'Content-Type': file.type
          }
        },
      ).then(result => {
        return result.data
      })
      .catch((err) => {
        if (err.response && err.response.status && err.response.status >= 500 && number <= options.retries) {
          retry(err);
        } else if (err.response && err.response.data) {
          throw err.response.data;
        } else {
          throw {error_code: "bad_request_error", "error_message": "Something went wrong - please try again."}
        }
      });
    });
  },
  getMetadata: (fullPath, options={retries:2, useCache:false}) => {
    // Note: these headers ensure we always fetch the most up-to-date
    // version of the requested JSON file. 
    let headers = new Headers();
    if (!(options.useCache)) {
      headers.append('pragma', 'no-cache');
      headers.append('cache-control', 'no-cache');
    }

    return promiseRetry((retry, number) => {
      return fetch(fullPath, headers).then(
        function (result) { // success handler
          if (!(result.ok)) {
            throw new Error("Invalid Metadata Response")
          }
          return result.json()
        },
        function (err) { // failure handler
          if (err.response && err.response.status && err.response.status >= 500 && number <= options.retries) {
            retry(err);
          } else if (err.response && err.response.data) {
            throw err.response.data;
          } else {
            throw {error_code: "bad_request_error", "error_message": "Something went wrong - please try again."}
          }
        }
     ).catch((err) => {
          if (err.response && err.response.status && err.response.status >= 500 && number <= options.retries) {
            retry(err);
          } else if (err.response && err.response.data) {
            throw err.response.data;
          } else {
            throw {error_code: "bad_request_error", "error_message": "Something went wrong - please try again."}
          }
        });
    });
  },
};

const NeuroFitBackend = {
  fetchAppConfig: ({tz, language_code}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/config`, {tz, language_code}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  createAccount: ({email_address, first_name, device_uuid, apple_authorization_code, tz, language_code}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/account/create`, {email_address, first_name, device_uuid, apple_authorization_code, tz, language_code}, {retries:0}),
  sendResetPasswordLink: ({email_address}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/account/password_reset/link`, {email_address}, {retries:0}),
  validateResetPasswordToken: ({reset_password_token}) =>
    requests.put(`${APP_BACKEND_BASE_URL}/account/password_reset/token/validate`, {reset_password_token}, {retries:0}),
  saveNewPassword: ({reset_password_token, new_password, device_uuid}) =>
    requests.put(`${APP_BACKEND_BASE_URL}/account/password_reset/complete`, {reset_password_token, new_password, device_uuid}, {retries:0}),

  // Authorized
  redeemSubscriptionAccessCode: ({invite_code}) => 
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/purchase/complete/access_code`, {invite_code}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  completeInAppPurchase: ({transaction, product_id, country_code}) => 
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/purchase/complete/in_app`, {transaction, product_id, country_code}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveUserCompletedOnboarding: ({}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/assessment/onboarding`, {}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  savePersonalizationOnboardingResult: ({selected_goal, selected_life_aspect, life_aspect_current_state, life_aspect_preferred_state}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/assessment/onboarding/personalize`, {selected_goal, selected_life_aspect, life_aspect_current_state, life_aspect_preferred_state}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  createPersonalizedLearnArticles: ({category_tag, query_text, is_search}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/recommendation/article`, {category_tag, query_text, is_search}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  getArticleMetadata: ({filename}) =>
    requests.getMetadata(`${DefaultCdnBaseUrl}/learn/json/${filename}.json`, {retries:2, useCache: false}),
  saveArticleStart: ({article_id}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/assessment/article/start`, {article_id}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveArticleCompletion: ({article_id, event_uuid, is_daily_learn_article}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/assessment/article/completion`, {article_id, event_uuid, is_daily_learn_article}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveArticleRating: ({article_id, event_uuid, article_rating}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/assessment/article/rating`, {article_id, event_uuid, article_rating}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  requestAccountDeletion: ({}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/account/delete/request`, {}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveAppFeedback: ({app_feedback_text}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/account/feedback`, {app_feedback_text}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  createInitialDiscoveryQuizResult: (body) =>
    requests.post(`${HRV_API_BACKEND_BASE_URL}/quiz/result`, body, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  createInAppQuizResult: (body) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/assessment/discovery`, body, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  incrementAppReviewRequestCount: ({}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/review_request_count/increment`, {}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveRateAppExperienceResult: ({star_rating}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/assessment/rate_app_experience`, {star_rating}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveConfigureNotificationSettings: (body) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/notification/settings`, body, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveFcmPushNotificationToken: ({notification_token}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/notification/token`, {notification_token}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveUpdatedAccountDetails: ({first_name, email_address, password}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/account/details`, {first_name, email_address, password}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),

  // Premium Authorized
  saveSelfCheckInResult: (body) =>
    requests.post(`${APP_BACKEND_BASE_URL}/premium/authorized/assessment/check-in`, body, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveRestartProgram: ({clear_existing_data}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/premium/authorized/program/restart`, {clear_existing_data}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  createPersonalizedNeuroFitExercise: ({current_mood, desired_mood}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/premium/authorized/recommendation/exercise`, {current_mood, desired_mood}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveExerciseResult: (body) =>
    requests.post(`${APP_BACKEND_BASE_URL}/premium/authorized/assessment/exercise`, body, {retries:3, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),

  // CLEAR Journaling
  createClearJournalThread: ({selected_life_aspect, selected_life_aspect_current_state}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/premium/authorized/journal/thread`, {selected_life_aspect, selected_life_aspect_current_state}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  sendClearJournalThreadChatMessage: (body) =>
    requests.post(`${HRV_API_BACKEND_BASE_URL}/journal-thread/chat-update`, body, {retries:1, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  saveClearJournalThreadChatReaction: ({journal_thread_id, journal_thread_message_id, reaction_name}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/premium/authorized/journal/thread/message/reaction`, {journal_thread_id, journal_thread_message_id, reaction_name}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),

  // Trainer Data
  getTrainerLinkedStatus: () =>
    requests.get(`${APP_BACKEND_BASE_URL}/premium/authorized/trainer/status`, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  updateTrainerLinkedStatus: ({trainer_invite_code}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/premium/authorized/trainer/status`, {trainer_invite_code}, {retries: 2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),

  // HRV API
  createHrvAnalysisSignedUploadUrl: () =>
    requests.post(`${HRV_API_BACKEND_BASE_URL}/hrv-task/upload-url`, {}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  createHrvAnalysisTask: () =>
    requests.post(`${HRV_API_BACKEND_BASE_URL}/hrv-task`, {}, {retries:3, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  getHrvAnalysisResults: (body) =>
    requests.post(`${HRV_API_BACKEND_BASE_URL}/hrv-task/status`, body, {retries:3, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  uploadHrvReadingData: ({reading_data, signed_upload_url_object}) =>
    requests.postFormJsonFile(signed_upload_url_object.url, signed_upload_url_object.fields, {reading_data}, {retries:3}),

  // Auth
  validateUserForLogin: ({email_address}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/account/validate`, {email_address}),
  loginUser: ({email_address, password, device_uuid, apple_authorization_code}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/account/login`, {email_address, password, device_uuid, apple_authorization_code}),
  logoutUser: ({}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/authorized/account/logout`, {}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),

  // Admin:
  adminGrantAppAccess: ({email_address, first_name, months_premium_access, send_invite_email}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/app-admin/app/invite/create`, {first_name, email_address, months_premium_access, send_invite_email}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),
  adminCreateTrainerDashboardInvite: ({email_address, first_name, send_invite_email}) =>
    requests.post(`${APP_BACKEND_BASE_URL}/app-admin/trainer-dashboard/invite/create`, {first_name, email_address, send_invite_email}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getCsrfTokenFromCookies()}}),

  fetchQuizResult: ({filename}) =>
    requests.getMetadata(`${DefaultQuizResultCdnBaseUrl}/${filename}.json`, {retries:2, useCache: false}),

  submitCertificationVideoRegistration: (body) =>
    requests.post(`${HRV_API_BACKEND_BASE_URL}/register-certification`, body, {retries:3}),

  submitTrainingRegistrationDetails: (body) =>
    requests.post(`${HRV_API_BACKEND_BASE_URL}/register-live-training`, body, {retries:3}),

  retrieveCertificationUniqueCodeDetails: ({unique_code}) =>
    requests.getMetadata(`${DefaultCertificationCodesCdnBaseUrl}/temp/${unique_code}.json`, {retries:2, useCache: true}),
  createCertificationPaymentIntent: ({affiliate_code, is_course}) =>
    requests.post(`${HRV_API_BACKEND_BASE_URL}/certification-payment-intent`, {affiliate_code, is_course}, {retries:3}),
  createBookPaymentIntents: ({affiliate_code}) =>
    requests.post(`${HRV_API_BACKEND_BASE_URL}/book-payment-intents`, {affiliate_code}, {retries:3}),
};

const NeuroFitDashboardBackend = {
    // Dashboard
  dashboardFetchAppConfig: ({tz}) =>
    requests.post(`${DASHBOARD_APP_BACKEND_BASE_URL}/config`, {tz}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getDashboardCsrfTokenFromCookies()}}),
  dashboardCreateAccount: ({first_name, last_name, occupation, professional_url, payouts_destination_country_code, email_address, password, device_uuid}) =>
    requests.post(`${DASHBOARD_APP_BACKEND_BASE_URL}/account/create`, {first_name, last_name, occupation, professional_url, payouts_destination_country_code, email_address, password, device_uuid}, {retries:0}),
  dashboardSendResetPasswordLink: ({email_address}) =>
    requests.post(`${DASHBOARD_APP_BACKEND_BASE_URL}/account/password_reset/link`, {email_address}, {retries:0}),
  dashboardValidateResetPasswordToken: ({reset_password_token}) =>
    requests.put(`${DASHBOARD_APP_BACKEND_BASE_URL}/account/password_reset/token/validate`, {reset_password_token}, {retries:0}),
  dashboardSaveNewPassword: ({reset_password_token, new_password, device_uuid}) =>
    requests.put(`${DASHBOARD_APP_BACKEND_BASE_URL}/account/password_reset/complete`, {reset_password_token, new_password, device_uuid}, {retries:0}),
  dashboardLoginUser: ({email_address, password, device_uuid}) =>
    requests.post(`${DASHBOARD_APP_BACKEND_BASE_URL}/account/login`, {email_address, password, device_uuid}),
  dashboardFetchAffiliateProfile: () =>
    requests.get(`${DASHBOARD_APP_BACKEND_BASE_URL}/authorized/affiliate/profile`, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getDashboardCsrfTokenFromCookies()}}),
  dashboardCreatePayoutsOnboardingUrl: () =>
    requests.post(`${DASHBOARD_APP_BACKEND_BASE_URL}/authorized/affiliate/payouts/onboarding_link`, {}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getDashboardCsrfTokenFromCookies()}}),
  dashboardCreatePayoutsLoginUrl: () =>
    requests.post(`${DASHBOARD_APP_BACKEND_BASE_URL}/authorized/affiliate/payouts/login_link`, {}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getDashboardCsrfTokenFromCookies()}}),
  dashboardUpdateAffiliateCode: ({updated_affiliate_code}) =>
    requests.put(`${DASHBOARD_APP_BACKEND_BASE_URL}/authorized/affiliate/code`, {updated_affiliate_code}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getDashboardCsrfTokenFromCookies()}}),
  dashboardFetchClientList: () =>
    requests.get(`${DASHBOARD_APP_BACKEND_BASE_URL}/authorized/clients`, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getDashboardCsrfTokenFromCookies()}}),
  dashboardFetchClientDetails: ({client_user_id}) =>
    requests.post(`${DASHBOARD_APP_BACKEND_BASE_URL}/authorized/client/profile`, {client_user_id}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getDashboardCsrfTokenFromCookies()}}),
  dashboardRemoveClient: ({client_user_id}) =>
    requests.put(`${DASHBOARD_APP_BACKEND_BASE_URL}/authorized/client/remove`, {client_user_id}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getDashboardCsrfTokenFromCookies()}}),
  dashboardCreateAppPrePurchasePaymentIntent: ({client_first_name, client_email_address, months_app_access}) =>
    requests.post(`${DASHBOARD_APP_BACKEND_BASE_URL}/authorized/app-pre-purchase/payment-intent`, {client_first_name, client_email_address, months_app_access}, {retries:2, headers:{[CSRF_TOKEN_HEADER]: getDashboardCsrfTokenFromCookies()}}),
  dashboardLogoutUser: ({}) =>
    requests.post(`${DASHBOARD_APP_BACKEND_BASE_URL}/authorized/account/logout`, {}, {retries:0, headers:{[CSRF_TOKEN_HEADER]: getDashboardCsrfTokenFromCookies()}}),
};

export default {
  NeuroFitBackend,
  NeuroFitDashboardBackend,
};
