import React from 'react'

import AppReviewPanel from './AppReviewPanel'

const trainerTestimonialObjects = [
  {
    title: "Well organised, succinct and engaging",
    type: "text",
    dateString: "Jan 17",
    username: "Dr. Catherine G.",
    occupation: "Embodiment Specialist",
    countryFlag: "🇦🇺",
    platform: "apple",
    description: "I found the course delivery to be very well organised, succinct and engaging. The techniques are very practical and applicable. All the key terms are clearly defined and explained to the depth required without going into extraneous detail. Whether it's for your own self-development or to adopt tools and techniques you can use with clients, NeuroFit is awesome!"
  },
  {
    title: "Loved every second of this training",
    type: "text",
    dateString: "Mar 6",
    username: "Sarah",
    occupation: "Doctor of Physical Therapy",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "I am a DPT focusing on holistic care and CNS regulation in my practice. The exercises I've learned in training and in the app are so accessible and simple and easy to incorporate into everyday life. I especially love the ENS massage and tree shaking and sacred rage! I have known I want to focus on sustainable CNS regulation for a while, but the NF training has finally given me a fantastic guide on how to make that a reality and focus my marketing and particular niche. I and many of my clients are athletes and/or have struggled with anxiety/trauma/self-esteem for most of our lives, and initial implementation of this training has already helped break down barriers so much!!"
  },
  {
    title: "Exercises That Work",
    type: "text",
    dateString: "Apr 3",
    username: "Kerry",
    occupation: "Therapist + LCSW",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "I’ve been using the Neurofit app for about a month now and I’ve had an incredible experience learning quick and effective exercises that actually work! I’m a therapist myself and I’ve always had a general understanding of how to regulate myself and others through physical movement but there was always something missing. Nothing was specific enough. What I learned through Neurofit is that we don’t always need the same thing! What works for you in one moment might not be what works for you in the next, depending on whats happening in your Nervous System specifically. The exercises in the app are more tailored to your current state which makes them more effective! So far I’m really impressed with the app and I plan to continue using it myself and recommending it to others."
  },
  {
    title: "Great Certification Training",
    type: "text",
    dateString: "May 2",
    username: "Silvy",
    occupation: "Military Mental Health Practitioner",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "As a Psych mental health nurse practitioner who recently finished training on polyvagal theory the NeuroFit certification has been a great compliment in training. It is nice to have different ways to bring awareness and calm to the nervous system. With my main clients being in the military offering exercises to focus on balancing the nervous system will be a modality that is familiar and useful to them. Great education provided and I’m looking forward to incorporating."
  },
  {
    title: "NeuroFit is an answered prayer!",
    type: "text",
    dateString: "Mar 6",
    username: "Jackie",
    occupation: "Master EFT Trainer",
    countryFlag: "🇺🇸",
    platform: "apple",
    description: "I wanted my clients to be able to see their results, and not just subjective results! With the app, they can feel even more motivated to stick to new healthier habits! I have already seen how much more motivated I am to choose wellness over bad habits when I have the app's accountability at my back. Goodbye glass of wine, nighttime TV, or unhealthy social media habits. The app feels like a fairy godmother on my shoulder that reminds me to choose my BEST self, instead of my conditioned self. It is a game-changer for me and will be a game-changer for my clients who want to commit to consistent daily practice. Cheers to the future!"
  }
]

const TopTrainerTestimonials = ({innerWidth}) => (
  <div className="disable-scrollbars" style={{scrollSnapType: "x mandatory", alignItems: "center", marginTop: `3vh`, padding: "5px 0px", overflowX: "scroll", overflowY: "hidden", display: "flex", WebkitOverflowScrolling: "touch"}}>
    {trainerTestimonialObjects.map(review => (
      <AppReviewPanel
        key={review.username}
        title={review.title}
        reviewStars={review.stars}
        dateString={review.dateString}
        username={review.username}
        occupation={review.occupation}
        countryFlag={review.countryFlag}
        description={review.description}
      />
    ))}
  </div>
)

export default TopTrainerTestimonials;