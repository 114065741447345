import {
  COMMON_TRIGGER_REQUEST,
  COMMON_UPDATE_REQUEST_STATE,
  COMMON_UPDATE_RESET_PASSWORD_TOKEN,
  COMMON_UPDATE_INNER_HEIGHT,
  COMMON_UPDATE_INNER_WIDTH,
  COMMON_UPDATE_VISIBLE_KEYBOARD_HEIGHT,
  COMMON_UPDATE_SAFE_AREA_INSETS,
  COMMON_UPDATE_IS_IOS_PWA,
  COMMON_UPDATE_ANDROID_DEFERRED_PROMPT,
  COMMON_UPDATE_NATIVE_APP_OPEN_COUNT,
  COMMON_UPDATE_PUSH_NOTIFICATION_TOKEN,
  COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS,
  COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOADED,
  COMMON_UPDATE_CAMERA_PERMISSIONS,
  COMMON_UPDATE_DEEPLINKED_ARTICLE_KEY,
  COMMON_UPDATE_DEEPLINKED_AFFILIATE_ID,
  COMMON_UPDATE_APP_STATE_IS_ACTIVE,
  COMMON_UPDATE_CURRENT_APP_VERSION,
  COMMON_UPDATE_NATIVE_COMPLETION_SOUND_PRELOADED,
  COMMON_UPDATE_APP_ACTION_TYPE,
  COMMON_UPDATE_IS_DEEPLINKED_PAYWALL_ACTION,
  COMMON_UPDATE_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION,
  COMMON_UPDATE_QUIZ_RESULT_KEY,
  COMMON_UPDATE_CERTIFICATION_AFFILIATE_CODE,
  COMMON_UPDATE_DEPLOYMENT_CONFIG,
  COMMON_UPDATE_NATIVE_APP_IS_OFFLINE,
  COMMON_UPDATE_EXPERIMENT_FLAGS,
  COMMON_UPDATE_DEVICE_IDENTIFIER,
  COMMON_UPDATE_SELECTED_APP_ICON,
  COMMON_UPDATE_EXPERIMENT_FLAGS_WEB,
  COMMON_RESET_STATE,
} from '../constants/actionTypes';

import {
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates';

export const resetCommon = () => {
  return {
    type: COMMON_RESET_STATE
  }
};

export const commonTriggerRequest = (key) => {
  return {
    type: COMMON_TRIGGER_REQUEST + key,
    payload: {
      key,
    }
  }
};

export const commonUpdateRequestState = (key, state, result=undefined) => {
  let payload = {
    state,
  };
  if (!!(result)) {
    if (state === REQUEST_SUCCESS) {
      payload.result = result
    }
    if (state === REQUEST_ERROR) {
      payload.error = result;
    }
  }
  return {
    type: COMMON_UPDATE_REQUEST_STATE,
    key,
    payload,
  }
}

export const commonUpdateResetPasswordToken = token => {
  return {
    type: COMMON_UPDATE_RESET_PASSWORD_TOKEN,
    payload: token
  }
}

export const commonUpdateInnerHeight = (innerHeight) => {
  return {
    type: COMMON_UPDATE_INNER_HEIGHT,
    payload: innerHeight,
  }
}

export const commonUpdateInnerWidth = (innerWidth) => {
  return {
    type: COMMON_UPDATE_INNER_WIDTH,
    payload: innerWidth
  }
}

export const commonUpdateVisibleKeyboardHeight = (visibleKeyboardHeight) => {
  return {
    type: COMMON_UPDATE_VISIBLE_KEYBOARD_HEIGHT,
    payload: visibleKeyboardHeight
  }
}

export const commonUpdateSafeAreaInsets = (safeAreaInsets) => {
  return {
    type: COMMON_UPDATE_SAFE_AREA_INSETS,
    payload: safeAreaInsets,
  }
}

export const commonUpdateAndroidDeferredPrompt = (prompt) => {
  return {
    type: COMMON_UPDATE_ANDROID_DEFERRED_PROMPT,
    payload: prompt
  }
}

export const commonUpdateIsIOSPWA = (isIOSPWA) => {
  return {
    type: COMMON_UPDATE_IS_IOS_PWA,
    payload: isIOSPWA
  }
}

export const commonUpdateNativeAppOpenCount = count => {
  return {
    type: COMMON_UPDATE_NATIVE_APP_OPEN_COUNT,
    payload: count
  }
}

export const commonUpdatePushNotificationToken = (token) => {
  return {
    type: COMMON_UPDATE_PUSH_NOTIFICATION_TOKEN,
    payload: token
  }
}

export const commonUpdateInAppPurchaseProducts = products => {
  return {
    type: COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS,
    payload: products
  }
}

export const commonUpdateInAppPurchaseProductsLoaded = areLoaded => {
  return {
    type: COMMON_UPDATE_IN_APP_PURCHASE_PRODUCTS_LOADED,
    payload: areLoaded
  }
}

export const commonUpdateCameraPermissions = permissions => {
  return {
    type: COMMON_UPDATE_CAMERA_PERMISSIONS,
    payload: permissions
  }
}

export const commonUpdateDeepLinkedArticleKey = articleKey => {
  return {
    type: COMMON_UPDATE_DEEPLINKED_ARTICLE_KEY,
    payload: articleKey
  }
}

export const commonUpdateDeepLinkedCertificationAffiliateId = affiliateId => {
  return {
    type: COMMON_UPDATE_DEEPLINKED_AFFILIATE_ID,
    payload: affiliateId
  }
}

export const commonUpdateAppStateIsActive = isActive => {
  return {
    type: COMMON_UPDATE_APP_STATE_IS_ACTIVE,
    payload: isActive
  }
}

export const commonUpdateCurrentAppVersion = appVersion => {
  return {
    type: COMMON_UPDATE_CURRENT_APP_VERSION,
    payload: appVersion
  }
}

export const commonUpdateNativeCompletionSoundPreloaded = () => {
  return {
    type: COMMON_UPDATE_NATIVE_COMPLETION_SOUND_PRELOADED,
    payload: true
  }
}

export const commonUpdateAppActionItemType = itemType => {
  return {
    type: COMMON_UPDATE_APP_ACTION_TYPE,
    payload: itemType
  }
}

export const commonUpdateIsDeeplinkedPaywallAction = isDeeplinkedAction => {
  return {
    type: COMMON_UPDATE_IS_DEEPLINKED_PAYWALL_ACTION,
    payload: isDeeplinkedAction
  }
}

export const commonUpdateIsDeeplinkedDiscoveryQuizAction = isDeeplinkedAction => {
  return {
    type: COMMON_UPDATE_IS_DEEPLINKED_DISCOVERY_QUIZ_ACTION,
    payload: isDeeplinkedAction,
  }
}

export const commonUpdateQuizResultKey = quizResultKey => {
  return {
    type: COMMON_UPDATE_QUIZ_RESULT_KEY,
    payload: quizResultKey
  }
}

export const commonUpdateSessionDeploymentConfig = deploymentConfig => {
  return {
    type: COMMON_UPDATE_DEPLOYMENT_CONFIG,
    payload: deploymentConfig,
  }
}

export const commonUpdateNativeAppIsOffline = isOffline => {
  return {
    type: COMMON_UPDATE_NATIVE_APP_IS_OFFLINE,
    payload: isOffline
  }
}

export const commonUpdateExperimentFlags = experimentFlags => {
  return {
    type: COMMON_UPDATE_EXPERIMENT_FLAGS,
    payload: experimentFlags
  }
}

export const commonUpdateDeviceIdentifier = deviceIdentifier => {
  return {
    type: COMMON_UPDATE_DEVICE_IDENTIFIER,
    payload: deviceIdentifier
  }
}

export const commonUpdateSelectedAppIcon = appIconName => {
  return {
    type: COMMON_UPDATE_SELECTED_APP_ICON,
    payload: appIconName
  }
}

export const commonUpdateCertificationAffiliateCode = affiliateCode => {
  return {
    type: COMMON_UPDATE_CERTIFICATION_AFFILIATE_CODE,
    payload: affiliateCode
  }
}

export const commonUpdateExperimentFlagsWeb = experimentFlagsWeb => {
  return {
    type: COMMON_UPDATE_EXPERIMENT_FLAGS_WEB,
    payload: experimentFlagsWeb
  }
}
