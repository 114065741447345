import React, {Suspense} from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'
import DownloadButtonsSection from './common/DownloadButtonsSection'
import AutoPlayInlineVideoContainer from './common/AutoPlayInlineVideoContainer'
import PieChartInsightSection from './common/PieChartInsightSection'
import LineGraph from './common/LineGraph'
import NeuroFitProgressRing from './common/NeuroFitProgressRing'
import MembershipFeatureDescription from './common/MembershipFeatureDescription'
import AppReviewPanel from './common/AppReviewPanel'
import UniversitySourcesSection from './common/UniversitySourcesSection'
import FaqSection from './common/FaqSection'
import LandingPageLinksModal from './modals/LandingPageLinksModal'
import QrCodeDownloadModal from './modals/QrCodeDownloadModal'


import { history } from '../history'

import {
  subdomainUrls,
  recordSignupFunnelEventGA,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  ProductionBaseUrl,
  ProductionWorkshopsUrl,
  ProductionCertificationUrl,
  delay,
  DefaultAspectRatio,
  recordLinkClickAndRedirect,
  recordPixelEventIfAvailable,
  getCurrentYear,
  MinDesktopWidthPixels,
  initializePixelIfAvailable,
  isProduction,
  onceEventListener,
} from '../utils'

import {
  AppleSvg,
  AndroidSvg,
  HamburgerSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK,
  GA_LABEL_CLICKED_WORKSHOP_BUTTON,
  GA_LABEL_CLICKED_CERTIFICATION_BUTTON,
  GA_LABEL_CLICKED_BOOK_BUTTON,
  GA_LABEL_CLICKED_QUIZ_BUTTON,
} from '../constants/gaEventLabels'

import TextLoop from "react-text-loop";
import StarRatings from 'react-star-ratings';

import loadable from '@loadable/component'

const MobileAppTestimonials = loadable(() => import('./common/MobileAppTestimonials'))

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const LandingPageFAQItems = [
  {
    key: "background_science_header",
    is_header: true,
    text: "Logistics & Science"
  },
  {
    key: "daily_commitment",
    question: "What is the daily time commitment for success?",
    answer: "Most members spend about 5 minutes a day inside the app. Beyond this, the recommended embodiment routine takes about 30 minutes each day (10 minutes each of social play, exercise, and stillness)."
  },
  {
    key: "science",
    question: "What is the science behind NeuroFit?",
    answer: (
      <span>
        <span>{"NeuroFit is a research-based and data-informed product. The core components included in our nervous system approach are informed by neuroscience research from leading universities, and the data from tens of thousands of sessions on our app. Our list of references is updated regularly and can be viewed "}</span>
        <span onClick={() => {window.open("/references", "_blank", "noopener noreferrer")}} style={{fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2, cursor: "pointer"}}>{"here"}</span>
        <span>{"."}</span>
      </span>
    )
  },
  {
    key: "hrv_methodology",
    question: "How does your HRV measurement tech work?",
    answer: "To measure HRV, NeuroFit reads changes in finger color via your phone's camera, to detect naturally occurring changes in heartbeat rhythm. This clinically validated technique is known as \"photoplethysmography\", and achieves a real-world accuracy of 96% compared to gold-standard (ECG-comparable) sensors in the appropriate conditions (eg, lighting, exposure, and finger pressure)."
  },
  {
    key: "app_design_header",
    is_header: true,
    text: "App Design"
  },
  {
    key: "simple_app",
    question: "The app's design seems simple and minimalist. Why is this?",
    answer: "This is intentional: the nervous system is balanced through action, not accumulating knowledge. Two common roadblocks when resolving nervous system dysegulation are overthinking, and the dopamine release that comes from consuming excessive information. The app experience is designed to prevent both of these, increasing the likelihood that our members take action."
  },
  {
    key: "balance_score",
    question: "How should I interpret my daily BALANCE score?",
    answer: "The NeuroFit Balance Score is a percentage measuring the nervous system's overall flexibility and readiness to be challenged. It's a short-term indicator of nervous system strength, resilience and recovery on a given day, and is measured relative to other nervous system readings you've taken lately."
  },
  {
    key: "membership_header",
    is_header: true,
    text: "Membership"
  },
  {
    key: "membership_logistics",
    question: "What are the logistics of membership?",
    answer: "After a 3-day free trial, membership can be accessed with either a 3 month or yearly subscription. Membership is auto-renewing and can be managed through the App Store or Play Store at any time."
  },
  {
    key: "membership_price_duration",
    question: "What is the investment for membership?",
    answer: "After a 3-day free trial, membership is priced at either $35.99 USD per 3 months, or $124.99 USD per year."
  },
  {
    key: "membership_time",
    question: "Why the 3 month membership plan?",
    answer: "Creating long-lasting changes in the nervous system takes about 6 weeks (the length of our program). To maximize results, we want you to commit to these changes and to be able to complete the program twice in one membership cycle. We have also found that this price point self-selects for members who are prepared to take ownership of their well-being."
  }
]

let defaultShowAnswer = {}

LandingPageFAQItems.map(key => defaultShowAnswer[key] = false)

class LandingPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showAnswer: defaultShowAnswer,
      showSettingsModal: false,
      showQrCodeModal: false,
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isVideoLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      hasScrolled: false
    }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`
      const hasScrolled = true
      this.setState({headerBackgroundColor, heroOverlayColor, dropShadowColor, hasScrolled, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity})
    } else {
      this.setState({headerBackgroundColor: "transparent", heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, hasScrolled: true, headerBorderColor: "transparent", videoOpacity: 1})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      i18n,
      isIOSDevice,
      isAndroidDevice
    } = this.props

    const {show, showAnswer, learnMoreRef, showSettingsModal, showQrCodeModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isVideoLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity, hasScrolled} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const phoneAspectRatio = 1841 / 907

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")

    const currentYear = getCurrentYear()

    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        <CSSTransition
          in={show}
          timeout={400}
          classNames={"fade-in-400"}
          onExited={() => {}}
        >
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
            <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
            <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: "min(20px, 4%)", paddingBottom: 12, backgroundColor: headerBackgroundColor, zIndex: 1000, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
              <NeuroFitNamedLogoSvg
                logoSize={"min(5.75vw, 24px)"}
                color={"#000000"}
                dropShadowColor={logoOutlineColor}
                onClick={() => window.location = "/"}
              />
              {!isMobile ? (
                <div style={{display: "flex", alignItems: "center"}}>
                  <span
                    onClick={() => {
                      recordPixelEventIfAvailable("StartTrial")
                      if (isIOSDevice || isAndroidDevice) {
                        recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK, appDownloadUrl)
                      } else {
                        this.setState({showQrCodeModal: true})
                      }
                    }}
                    style={{cursor: "pointer", fontFamily: "Futura Medium", textShadow, fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                  >
                    {"App"}
                  </span>
                  <span style={{display: "inline-block", margin: "0px 0.5em", textShadow, fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span
                    onClick={() => {
                      recordLinkClickAndRedirect(GA_LABEL_CLICKED_CERTIFICATION_BUTTON, "/certification")
                    }}
                    style={{fontFamily: "Futura Medium", fontSize: "min(4vw, 15px)", textShadow, textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                  >
                    {"Certification"}
                  </span>
                </div>
              ) : (
                <button
                  onClick={() => {
                    this.setState({showSettingsModal: true})
                  }}
                  style={{padding: 0, margin: "0px 7px", backgroundColor: "transparent", border: "none"}}
                >
                  <HamburgerSvg size="min(6vw, 30px)" dropShadow={svgDropShadow} />
                </button>
              )}
            </div>
            <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, right: 0, backgroundColor: showSettingsModal ? "rgba(0, 0, 0, 0.5)" : "transparent", pointerEvents: "none", transition: "all 300ms ease-out", transitionProperty: "background-color", zIndex: 2000}}>
            </div>
            <div 
              id={"landing-page-container"}
              className={"disable-scrollbars"}
              ref={elem => this.scrollingContainerRef = elem}
              style={containerStyle}
              onClick={() => {
                this.manualStartRef.playVideo()
              }}
            >
              <div style={{textAlign: "left", width: "100vw", height: innerHeight, pointerEvents: "none"}}>
                <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", justifyContent: "center", zIndex: -2}}>
                  <div style={{width: videoWidthPixels, opacity: videoOpacity}}>
                    <AutoPlayInlineVideoContainer
                      videoPath={"/assets/videos/landing-page-exercises.mp4"}
                      baseContentUrl={ProductionBaseUrl}
                      widthPixels={videoWidthPixels}
                      isVideoVisible
                      registerManualStartEvent={triggerManualVideoStart => {
                        // Ensure Hero Video becomes controllable on iOS in Low Power Mode.
                        onceEventListener(window, "touchstart", function (e) {
                          triggerManualVideoStart()
                        });
                      }}
                      ref={elem => this.manualStartRef = elem}
                      onVideoLoad={() => {
                        this.setState({videoDownloadProgress: 1})
                        initializePixelIfAvailable()
                      }}
                    />
                  </div>
                </div>
                <CSSTransition
                  in={videoDownloadProgress < 1}
                  timeout={400}
                  classNames={"fade-in"}
                  onExited={() => {}}
                  unmountOnExit
                >
                  <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", alignItems: "center", justifyContent: "center", zIndex: -2}}>
                    <LoadingLogo
                      show={true}
                      hideAnimation={true}
                      responsiveSize={"100px"}
                      marginTop={0}
                      marginRight={"0px"}
                      color={"#CFD2DD"}
                      lineColor={"#EDEEF2"}
                      fontFamily={"Futura"}
                      showProgressionBar={false}
                    />
                  </div>
                </CSSTransition>
                <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", justifyContent: "center", zIndex: (topPageScrollProgress > 0) ? -1 : 0}}>
                  <div style={{display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}}>
                    <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: 60, pointerEvents: "none"}}>
                      <div style={{width: "88%", maxWidth: 700}}>
                        <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textShadow, textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", marginTop: "3vh", textTransform: "uppercase"}}>
                          <span>{`Beat Stress Fast W/ Nervous System Fitness™`}</span>
                        </div>
                      </div>
                    </div>
                    <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginBottom: "4vh", pointerEvents: "none"}}>
                      <div style={{width: "85%", maxWidth: 700, pointerEvents: "none"}}>
                        <DownloadButtonsSection
                          isIOSDevice={isIOSDevice}
                          isAndroidDevice={isAndroidDevice}
                          useWhiteButtons
                          onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                          onDownloadButtonClick={() => this.setState({hasScrolled: true})}
                        />
                        <div style={{display: "flex", justifyContent: "center", marginTop: "2vh", pointerEvents: "none"}}>
                          <svg onClick={() => this.learnMoreRef.scrollIntoView({behavior: "smooth"})} style={{pointerEvents: "all", cursor: "pointer", height: "min(12vw, 70px)", width: "min(12vw, 70px)"}} className="loading-pulse-animation-down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1" x="0px" y="0px"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g fill="#FFFFFF"><path d="M20,27.4142136 L32.7071068,14.7071068 L31.2928932,13.2928932 L20,24.5799999 L8.70710678,13.2928932 L7.29289322,14.7071068 L20,27.4142136 Z"></path></g></g></svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{position: "fixed", top: 0, width: innerWidth, backdropFilter: heroBackdropFilter, WebkitBackdropFilter: heroBackdropFilter, height: "100%", display: "flex", backgroundColor: heroOverlayColor, justifyContent: "center", zIndex: -1, pointerEvents: "none"}} onClick={() => this.manualStartRef.playVideo()}>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", padding: "calc(60px + 5vh) 0px", backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}} ref={elem => this.learnMoreRef = elem}>
                <div style={{height: "100%"}}>
                  <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                    <div style={{width: "85%", maxWidth: 700}}>
                      <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                        {"Backed By Data."}
                      </div>
                      <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", textTransform: "uppercase"}}>
                        {"Loved By Embodied Health Experts."}
                      </div>
                      <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        <span>{`NeuroFit is designed to improve well-being fast: on average, active members report `}</span>
                        <span style={{fontFamily: "Futura"}}>{`54% less stress after just 1 week.`}</span>
                      </div>
                      <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        <span style={{fontFamily: "Futura"}}>{`Hundreds of therapists, doctors, health coaches, and fitness trainers`}</span>
                        <span>{` use our nervous system approach with clients.`}</span>
                      </div>
                    </div>
                  </div>
                  <div style={{textAlign: "left", width: "100vw", padding: `3vh 0px 3vh 0px`, opacity: 0.5}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
                      <div />
                      <img src="/images/well-and-good-black-square.svg" style={{width: 150, maxWidth: "18%"}} alt="NeuroFit has been featured in Well and Good." />
                      <img src="/images/body-and-soul-black.svg" style={{width: 150, maxWidth: "18%"}} alt="Body and Soul logo." />
                      <img src="/images/mamamia-logo-black.svg" style={{width: 150, maxWidth: "18%"}} alt="NeuroFit has been featured in Mamamia." />
                      <img src="/images/daily-mail-logo.webp" style={{width: 150, maxWidth: "18%"}} alt="NeuroFit has been featured in the Daily Mail." />
                      <div />
                    </div>
                  </div>
                  <div>
                    <div style={{textAlign: "center", margin: "0vh auto 3vh auto", opacity: 1.0}}>
                      <div>
                        <StarRatings
                          disabled
                          rating={4.8}
                          starRatedColor={"#000000"}
                          starEmptyColor={"#CFD2DD"}
                          starDimension={"min(8vw, 40px)"}
                          starSpacing={"min(2vw, 10px)"}
                          numberOfStars={5}
                        />
                      </div>
                      <div style={{display: "inline-block", fontSize: "min(5vw, 26px)", textAlign: "left", fontFamily: "Futura Medium", marginRight: "0.3em", textTransform: "uppercase"}}>
                        {"4.8/5 App Rating"}
                      </div>
                    </div>
                    <div style={{color: "black", width: "100%",display: "flex", justifyContent: "center", alignItems: "top"}}>
                      <div style={{width: "85%", maxWidth: 700}}>
                        <DownloadButtonsSection
                          isIOSDevice={isIOSDevice}
                          isAndroidDevice={isAndroidDevice}
                          onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", minHeight: `calc(${innerHeight}px - 120px - 10vh)`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid", padding: `calc(60px + 5vh) 0px`}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                      {"Nervous System Is The #1 Key To Modern Wellness"}
                    </div>
                    <div style={{marginTop: "4vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                      <div style={{marginTop: "6vh"}}>
                        {"Chronic stress imbalances the nervous system. Mindset falls short here: only body-based methods can overcome this."}
                      </div>
                      <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                        <div style={{width: "100%", maxWidth: 450}}>
                          <PieChartInsightSection
                            title={`EMBODIMENT VS. MINDSET`}
                            marginTop={"max(3vw, 16px)"}
                            disableDropDown
                            dataIsAvailable
                            data={{"embodiment": 80, "mindset": 20}}
                            insightText={`NeuroFit's embodiment approach speaks to 80% of the nervous system. Mindset only speaks to the other 20%.`}
                            dataCategories={[{title_name: "Embodiment", name: "embodiment", color: "#FFD634"}, {title_name: "Mindset", name: "mindset", color: "#9EA0DA"}]}
                          />
                        </div>
                      </div>
                      <div style={{marginTop: "8vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        <span>{"The good news? Nervous System Fitness improves holistic well-being fast:"}</span>
                      </div>
                      <div style={{display: "flex", marginTop: "2vh", width: "100%", justifyContent: "center"}}>
                        <div style={{backgroundColor: "#FFFFFF", width: "100%", maxWidth: 450, boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
                          <div style={{padding: "min(24px, max(3vw, 16px))"}}>
                            <div style={{fontSize: "min(5vw, 22px)", textAlign: "left", fontFamily: "Futura Medium"}}>
                              {"NERVOUS SYSTEM FITNESS:"}
                            </div>
                            <MembershipFeatureDescription
                              fontSize={"min(4vw, 18px)"}
                              fontFamily={"Futura"}
                              checkMarginTop={"0.15em"}
                              text={"Quickly Reduces Stress + Anxiety"}
                            />
                            <MembershipFeatureDescription
                              fontSize={"min(4vw, 18px)"}
                              fontFamily={"Futura"}
                              checkMarginTop={"0.15em"}
                              text={"Enhances Focus + Creativity"}
                            />
                            <MembershipFeatureDescription
                              fontSize={"min(4vw, 18px)"}
                              fontFamily={"Futura"}
                              checkMarginTop={"0.15em"}
                              text={"Improves Emotional + Mental Clarity"}
                            />
                            <MembershipFeatureDescription
                              fontSize={"min(4vw, 18px)"}
                              fontFamily={"Futura"}
                              checkMarginTop={"0.15em"}
                              text={"Optimizes Sleep + Accelerates Recovery"}
                            />
                            <MembershipFeatureDescription
                              fontSize={"min(4vw, 18px)"}
                              fontFamily={"Futura"}
                              checkMarginTop={"0.15em"}
                              text={"Boosts Immunity + Digestion"}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{marginTop: "8vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        <span>{"Within just 5 minutes of NeuroFit exercises, 95% of members report relief from real-time stress:"}</span>
                      </div>
                      <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                        <div style={{width: "100%", maxWidth: 450}}>
                          <LineGraph
                            dataIsAvailable
                            title={`REAL-TIME STRESS RELIEF`}
                            data={{
                              labels: ["0", "1", "2", "3"],
                              series: [[0, 88.8, 95, 98.6]],
                            }}
                            labelInterpolationFnc={(val) => `${val}%`}
                            highYValue={100}
                            hideLogo
                            xAxisTitle={"Exercises Completed (2-3 mins. each)"}
                          />
                        </div>
                      </div>
                      <div style={{marginTop: "6vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                        <span>{"Our seamless and highly effective approach is designed bottom-up with "}</span>
                        <span onClick={() => {window.open("/references", "_blank", "noopener noreferrer")}} style={{fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2, cursor: "pointer"}}>{"neuroscience research"}</span>
                        <span>{" from universities such as:"}</span>
                      </div>
                      <UniversitySourcesSection
                        isMobile={isMobile}
                        hideSecondRow
                        lazyLoadImages
                      />
                      <DownloadButtonsSection
                        isIOSDevice={isIOSDevice}
                        isAndroidDevice={isAndroidDevice}
                        onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", padding: "6vh 0vh", backgroundColor: "#000000"}}>
                <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-around"}}>
                  <img src="/images/well-and-good-laurel-white.webp" style={{width: "60%", maxWidth: 350}} loading="lazy" alt="NeuroFit eased my stress in ways meditation never has -Well and Good" />
                </div>
                <div style={{display: "flex", marginTop: "3vh", width: "100%", alignItems: "center", justifyContent: "space-around"}}>
                  <img src="/images/body-and-soul-laurel-white.webp" style={{width: "70%", maxWidth: 400}} loading="lazy" alt="I finally found something to calm my stressed out nervous system -Body and Soul" />
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                      {"Love From Our Members"}
                    </div>
                  </div>
                </div>
                <MobileAppTestimonials />
              </div>
              <div style={{textAlign: "left", width: "100vw", backgroundColor: "#EDEEF2", padding: `calc(60px + 5vh) 0px`, borderTop: "2px #000000 solid"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                      {"BECOME AN EXPERT NERVOUS SYSTEM FITNESS TRAINER"}
                    </div>
                    <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh 0px"}}>
                      {hasScrolled && (
                        <img src="/images/certification-hero-dash.webp" loading="lazy" style={{width: "100%", maxWidth: 600}} alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
                      )}
                    </div>
                    <div style={{marginTop: "4vh", fontSize: "min(5vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                      <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura", paddingBottom: "1vh", fontSize: "min(5vw, 25px)", textAlign: "left"}}>
                        {`Master nervous system regulation, somatic release exercises, and trauma-informed witnessing, and become a Certified Trainer with our:`}
                      </div>
                      <div style={{display: "flex", alignItems: "top", justifyContent: "left", padding: "0vh 2vh 1vh 2vh"}}>
                        <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                          {"•"}
                        </div>
                        <div style={{fontFamily: "Futura",  fontSize: "min(5vw, 25px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                          {"Nervous System Certification Course"}
                        </div>
                      </div>
                      <div style={{display: "flex", alignItems: "top", justifyContent: "left", padding: "0vh 2vh 1vh 2vh"}}>
                        <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                          {"•"}
                        </div>
                        <div style={{fontFamily: "Futura", fontSize: "min(5vw, 25px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                          {"Nervous System Trainer Tech+Toolkit"}
                        </div>
                      </div>
                      <div style={{display: "flex", alignItems: "top", justifyContent: "left", padding: "0vh 2vh 1vh 2vh"}}>
                        <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                          {"•"}
                        </div>
                        <div style={{fontFamily: "Futura", fontSize: "min(5vw, 25px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                          {"Done-For-You Business Suite, and"}
                        </div>
                      </div>
                      <div style={{display: "flex", alignItems: "top", justifyContent: "left", padding: "0vh 2vh 1vh 2vh"}}>
                        <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                          {"•"}
                        </div>
                        <div style={{fontFamily: "Futura", fontSize: "min(5vw, 25px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                          {"3 months of ongoing support, Live Coaching and Trainer Community"}
                        </div>
                      </div>
                      <div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                          <button
                            style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "4vh", color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                            onClick={() => {
                              recordLinkClickAndRedirect(GA_LABEL_CLICKED_CERTIFICATION_BUTTON, "/certification")
                            }}
                          >
                            <LoadingLogo
                              show={true}
                              hideAnimation={true}
                              responsiveSize={"min(6vw, 25px)"}
                              marginTop={"0px"}
                              padding={"0px"}
                              marginRight={"0.4em"}
                              color={"#FFFFFF"}
                              lineColor={"#000000"}
                              fontFamily={"Futura"}
                              text={"VIEW CERTIFICATION"}
                              showProgressionBar={false}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{color: "black", width: "100%", display: "flex", backgroundColor: "#FFFFFF", justifyContent: "center", alignItems: "top", borderTop: "2px #000000 solid", padding: "calc(60px + 5vh) 0px"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                    {"GET IN TOUCH"}
                  </div>
                  <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <div style={{marginTop: "min(6vh, 30px)", marginBottom: "min(6vh, 30px)", backgroundColor: "#EDEEF2", width: "100%", maxWidth: 500, boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)"}}>
                      <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura"}}>
                        {`PRESS & MEDIA`}
                      </div>
                      <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "left", fontFamily: "Futura"}}>
                        <a href="mailto:press@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                          {"press@neurofit.app"}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <div style={{backgroundColor: "#EDEEF2", marginBottom: "min(6vh, 30px)", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                      <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura"}}>
                        {`TEAM APP ACCESS`}
                      </div>
                      <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "left", fontFamily: "Futura"}}>
                        <a href="mailto:teams@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                          {"teams@neurofit.app"}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "center", marginBottom: "min(6vh, 30px)", width: "100%"}}>
                    <div style={{backgroundColor: "#EDEEF2", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                      <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura"}}>
                        {`APP CUSTOMER CARE`}
                      </div>
                      <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "left", fontFamily: "Futura"}}>
                        <a href="mailto:care@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                          {"care@neurofit.app"}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <div style={{backgroundColor: "#EDEEF2", marginBottom: "min(6vh, 30px)", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)", width: "100%", maxWidth: 500}}>
                      <div style={{fontSize: "min(6vw, 26px)", fontFamily: "Futura"}}>
                        {`CERTIFICATION HELP`}
                      </div>
                      <div style={{marginTop: 20, fontSize: "min(5vw, 20px)", textAlign: "left", fontFamily: "Futura"}}>
                        <a href="mailto:certification@neurofit.app" style={{display: "inline-block", color: "#000000", fontFamily: "Futura", textDecoration: "underline", textUnderlineOffset: 2}}>
                          {"certification@neurofit.app"}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", backgroundColor: "#EDEEF2", paddingTop: `calc(60px + 5vh)`, borderTop: "2px #000000 solid"}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingBottom: "calc(60px + 5vh)"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                      {"APP FAQ"}
                    </div>
                    {LandingPageFAQItems.map((f) => (
                      (f.is_header ? (
                        <div key={f.key} style={{fontSize: "min(7vw, 32px)", textTransform: "uppercase", margin: "min(9vh, 45px) 0px 0px 0px", color: "#000000", textAlign: "left", fontFamily: "Futura"}}>
                          {f.text}
                        </div>
                      ) : (
                        <FaqSection
                          key={f.key}
                          question={f.question}
                          answer={f.answer}
                          showAnswer={showAnswer[f.key]}
                          onToggleShowAnswer={() => this.setState({showAnswer: {...showAnswer, [f.key]: (!showAnswer[f.key])}})}
                          marginTop={"2vh"}
                        />
                      ))
                    ))}
                  </div>
                </div>
              </div>
              <div style={{padding: 15, paddingTop: 30, backgroundColor: "#EDEEF2", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", color: "#c1c4d2", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                <div style={{display: "inline-block"}}>
                  <span style={{color: "#c1c4d2"}}>
                    {`© NEUROFIT ${currentYear}`}
                  </span>
                  <span style={{display: "inline-block", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span style={{color: "#c1c4d2"}}>
                    {"ALL RIGHTS RESERVED"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
        <QrCodeDownloadModal
          show={showQrCodeModal}
          onHide={() => this.setState({showQrCodeModal: false})}
          safeAreaInsets={safeAreaInsets}
        />
        <LandingPageLinksModal
          show={showSettingsModal}
          onHide={() => this.setState({showSettingsModal: false})}
          onClickDownloadAppButton={() => {
            recordPixelEventIfAvailable("StartTrial")
            recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK, appDownloadUrl)
          }}
          onClickTrainerCertificationButton={() => {
            recordLinkClickAndRedirect(GA_LABEL_CLICKED_CERTIFICATION_BUTTON, "/certification")
          }}
          onClickQuizButton={() => {
            recordPixelEventIfAvailable("Lead")
            recordLinkClickAndRedirect(GA_LABEL_CLICKED_QUIZ_BUTTON, "/quiz")
          }}
          safeAreaInsets={safeAreaInsets}
        />
        {hasScrolled && (
          <iframe src={`${subdomainUrls.baseNativeAppUrl}/app?w=1`}  width="0" height="0" frameborder="0" style={{display: "none", width: 0, height: 0, border: 0, border: "none", position: "absolute"}} />
        )}
      </div>
    )
  }
}

export default LandingPage;