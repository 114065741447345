import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventResultGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  MinDesktopWidthPixels,
} from '../../utils'

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './LoadingLogo'
import AutoPlayInlineVideoContainer from './AutoPlayInlineVideoContainer'
import LineGraph from './LineGraph'
import UniversitySourcesSection from './UniversitySourcesSection'
import FaqSection from './FaqSection'


import {
  GA_LABEL_CLICKED_BOOK_CHECKOUT_BUTTON,
} from '../../constants/gaEventLabels'

import loadable from '@loadable/component'

const StripeCheckoutForm = loadable(() => import('./StripeCheckoutForm'))


const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class BookDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSettingsModal: false,
      learnMoreRef: React.createRef(),
      checkoutRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef()
    }
  }

  handleOnClickLearnMore = (event) => {
    //.current is verification that your element has rendered
    if (this.learnMoreRef.current){
        this.learnMoreRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  handleOnScrollToCheckout = () => {
    if (this.checkoutRef.current) {
        this.checkoutRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const logoLineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`

      const updatedState = {headerBackgroundColor, hasScrolled: true, heroOverlayColor, dropShadowColor, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, logoLineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#FFFFFF", logoLineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      isNasmPage,
    } = this.props


    const {show, hasScrolled, learnMoreRef, checkoutRef, showSettingsModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")

    return (
      <div 
        id={"book-page-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={containerStyle}
      >
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, right: 0, backgroundColor: "transparent", pointerEvents: "none", zIndex: 2000}}>
        </div>
        <div style={{textAlign: "left", width: "100vw", height: innerHeight, pointerEvents: "none"}}>
          <CSSTransition
            in={!isHeroImageLoaded}
            timeout={400}
            classNames={"fade-in"}
            onExited={() => {}}
            unmountOnExit
          >
            <div className={"video-loading-background-animation"} style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", alignItems: "center", justifyContent: "center", zIndex: -2}}>
              <LoadingLogo
                show={true}
                hideTitle={true}
                hideAnimation={true}
                responsiveSize={"100px"}
                marginTop={0}
                marginRight={"0px"}
                color={"#CFD2DD"}
                lineColor={"#EDEEF2"}
                fontFamily={"Futura"}
                showProgressionBar={false}
              />
            </div>
          </CSSTransition>
          <div style={{position: "fixed", top: 0, width: innerWidth, backgroundColor: "#EDEEF2", height: "100%", display: "flex", justifyContent: "center", zIndex: -2, opacity: isHeroImageLoaded ? 1.0 : 0.0, transition: "opacity 250ms linear"}}>
            <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <img
                src="/images/neurofit-book-cover.webp"
                fetchpriority="high"
                onLoad={() => {
                  this.setState({isHeroImageLoaded: true})
                  initializePixelIfAvailable()
                }}
                style={{maxWidth: "50%", maxHeight: "40%"}}
                alt="NeuroFit: An interactive guide to nervous system regulation. How to reduce stress, feel better in your body, and perform at your best."
              />
            </div>
          </div>
          <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", justifyContent: "center", zIndex: (topPageScrollProgress > 0) ? -1 : 0}}>
            <div style={{display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: 60, pointerEvents: "none"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", color: "#000000", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", marginTop: `calc(3vh + ${safeAreaInsets.top}px)`, textTransform: "uppercase"}}>
                    {`Nervous System, Simplified.`}
                  </div>
                </div>
              </div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginBottom: "5vh", pointerEvents: "none"}}>
                <div style={{width: "85%", maxWidth: 700, pointerEvents: "none"}}>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "3vh", color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                      onClick={() => {
                        recordPixelEventIfAvailable("InitiateCheckout", "book")
                        this.learnMoreRef.scrollIntoView({behavior: "smooth"})
                        recordFunnelEventResultGA(GA_LABEL_CLICKED_BOOK_CHECKOUT_BUTTON, {location: "hero"})
                      }}
                    >
                      <LoadingLogo
                        show={true}
                        hideAnimation={true}
                        hideLogo
                        responsiveSize={"min(6vw, 25px)"}
                        marginTop={"0px"}
                        padding={"0px"}
                        marginRight={"0.4em"}
                        color={"#FFFFFF"}
                        lineColor={"#000000"}
                        fontFamily={"Futura"}
                        text={"LEARN MORE"}
                        showProgressionBar={false}
                      />
                    </button>
                  </div>
                  <div style={{display: "flex", justifyContent: "center", marginTop: "3vh", pointerEvents: "none"}}>
                    <svg onClick={() => this.learnMoreRef.scrollIntoView({behavior: "smooth"})} style={{pointerEvents: "all", cursor: "pointer", height: "min(12vw, 70px)", width: "min(12vw, 70px)"}} className="loading-pulse-animation-down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1" x="0px" y="0px"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g fill="#000000"><path d="M20,27.4142136 L32.7071068,14.7071068 L31.2928932,13.2928932 L20,24.5799999 L8.70710678,13.2928932 L7.29289322,14.7071068 L20,27.4142136 Z"></path></g></g></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{position: "fixed", top: 0, width: innerWidth, backdropFilter: heroBackdropFilter, WebkitBackdropFilter: heroBackdropFilter, height: "100%", display: "flex", backgroundColor: heroOverlayColor, justifyContent: "center", zIndex: -1, pointerEvents: "none"}}>
          </div>
        </div>
        <div style={{padding: "calc(60px + 5vh) 0px", textAlign: "left", width: "100vw", borderTop: "2px #000000 solid", backgroundColor: "#FFFFFF"}} ref={elem => this.learnMoreRef = elem}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div>
                <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                  {"AN INTERACTIVE GUIDE FOR YOUR NERVOUS SYSTEM"}
                </div>
                <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  {`Master the basics of nervous system regulation to reduce stress, feel better in your body, and perform at your best.`}
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                  <img style={{width: `${100 * 70 / 85}%`, maxWidth: 400, margin: "5vh 0px"}} src="/images/amazon-new-release.webp" alt="Amazon #1 New Release: Neuropsychology, Physiological Aspects of Psychology" />
                </div>
                <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  {"In 6 quick chapters, learn how to:"}
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                    <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                      {"•"}
                    </div>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Recognize the six different states of the nervous system"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                    <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                      {"•"}
                    </div>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Diagnose your own nervous system through the interactive discovery quiz"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                    <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                      {"•"}
                    </div>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Re-balance your nervous system in real-time with somatic exercises, and"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                    <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                      {"•"}
                    </div>
                  <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Pinpoint the key habits and discernment needed to master nervous system regulation"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", padding: "calc(60px + 5vh) 0px", borderTop: "2px #000000 solid", backgroundColor: "#EDEEF2"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 500}}>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                {"CHECKOUT:"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "min(24px, max(3vw, 16px))", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                {"NEUROFIT BOOK"}
              </div>
              {hasScrolled && (
                <StripeCheckoutForm
                  isNative={isNative}
                  isBookPage
                  onApplyPromotionCode={promotionCode => {}}
                  onShowSuccessMessage={(successMessage) => {}}
                  onShowErrorMessage={(errorMessage) => {}}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", backgroundColor: "#EDEEF2"}}>
          <div style={{padding: 15, paddingBottom: 15 + safeAreaInsets.bottom, backgroundColor: "#EDEEF2", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
            <div style={{display: "inline-block"}}>
              <span style={{color: "#c1c4d2"}}>
                {"© NEUROFIT 2023"}
              </span>
              <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                {"•"}
              </span>
              <span style={{color: "#c1c4d2"}}>
                {"ALL RIGHTS RESERVED"}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BookDetails