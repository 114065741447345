import { Preferences } from '@capacitor/preferences';

const EXPERIMENTS_PREFERENCES_KEY = "experiments-v4"

// Native
export const EXPERIMENT_FLAGS_PAYWALL_ENABLE_DAY_ONE_PRICING = "experiment__paywall_day_one_pricing"

const ExperimentFlagKeys = [
  EXPERIMENT_FLAGS_PAYWALL_ENABLE_DAY_ONE_PRICING,
]

// Web
export const EXPERIMENT_FLAGS_WEB_CERT_OFFER_RED_BUTTON = "experiment__web_cert_offer_red_button";
export const EXPERIMENT_FLAGS_WEB_CERT_OFFER_DISPLAY_LIMITED_TIME_HERO_CTA_TEXT = "experiment__web_cert_offer_display_limited_time_hero_cta_text"
export const EXPERIMENT_FLAGS_WEB_CERT_OFFER_SHOW_PRESS_AT_BOTTOM_ABOVE_FOLD = "experiment__web_cert_offer_show_press_at_bottom_above_fold"
export const EXPERIMENT_FLAGS_WEB_CERT_OFFER_HIDE_ALL_SECTIONS_BELOW_FOLD = "experiment__web_cert_offer_hide_all_sections_below_fold"
export const EXPERIMENT_FLAGS_WEB_CERT_OFFER_HERO_CTA_SHOW_PERCENT_OFF = "experiment__web_cert_offer_hero_cta_show_percent_off"
export const EXPERIMENT_FLAGS_WEB_CERT_OFFER_HERO_PRESS_SHOW_WELL_AND_GOOD_LAUREL = "experiment__web_cert_offer_hero_press_show_well_and_good_laurel"

const WebExperimentFlagKeys = [
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_RED_BUTTON,
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_DISPLAY_LIMITED_TIME_HERO_CTA_TEXT,
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_SHOW_PRESS_AT_BOTTOM_ABOVE_FOLD,
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_HIDE_ALL_SECTIONS_BELOW_FOLD,
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_HERO_CTA_SHOW_PERCENT_OFF,
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_HERO_PRESS_SHOW_WELL_AND_GOOD_LAUREL,
]

const DEFAULT_EXPERIMENTS_ENABLED_FLAG = "experiments_enabled"
const EXPERIMENT_CURRENT_VERSION_FLAG = "experiments_current_version"
const EXPERIMENT_CURRENT_VERSION_VALUE = 3

const generateExperimentFlagValue = () => {
  return Math.random() >= 0.5
}

export const loadUserExperimentFlags = async (isWeb=false) => {
  // Retrieve Current Experiment Preferences.
  const { value } = await Preferences.get({ key: EXPERIMENTS_PREFERENCES_KEY })
  let experimentFlagsObject;
  try {
    experimentFlagsObject = JSON.parse(value)
  } catch (e) {
    console.log(`Initial Response: ${value}`)
    console.log(`Error Parsing Experiment Flags Response: ${e}`)
  }

  let experimentFlags = experimentFlagsObject || {}

  const configuredFlags = new Set(Object.keys(experimentFlags))

  // Set New Experiment Flags If Needed.
  const flagKeys = isWeb ? WebExperimentFlagKeys : ExperimentFlagKeys
  flagKeys.map(key => {
    if (!configuredFlags.has(key)) {
      experimentFlags[key] = generateExperimentFlagValue()
    }
  })

  experimentFlags[DEFAULT_EXPERIMENTS_ENABLED_FLAG] = true
  experimentFlags[EXPERIMENT_CURRENT_VERSION_FLAG] = EXPERIMENT_CURRENT_VERSION_VALUE

  // Store Updated Experiment Preferences.
  await Preferences.set({
    key: EXPERIMENTS_PREFERENCES_KEY,
    value: JSON.stringify(experimentFlags)
  })

  return experimentFlags
}

export const loadUserExperimentFlagsWeb = async () => {
  return await loadUserExperimentFlags(true/*isWeb*/)
}