// Common
export const REQ_FETCH_APP_CONFIG = 'fetchAppConfig'
export const REQ_FETCH_DEPLOYMENT_CONFIG = 'fetchDeploymentConfig'
export const REQ_SAVE_NOTIFICATION_REGISTRATION_RESULT = 'saveNotificationRegistrationResult'

// Login Page
export const REQ_CREATE_ACCOUNT = 'createAccount'
export const REQ_CREATE_ACCOUNT_APPLE = 'createAccountApple'
export const REQ_CREATE_ACCOUNT_GOOGLE = 'createAccountGoogle'
export const REQ_VALIDATE_USER_FOR_LOGIN = 'validateUserForLogin'
export const REQ_LOGIN_USER = 'logInUser'
export const REQ_LOGIN_USER_APPLE = 'logInUserApple'
export const REQ_LOGIN_USER_GOOGLE = 'logInUserGoogle'
export const REQ_SEND_RESET_PASSWORD_LINK = 'sendResetPasswordLink'
export const REQ_VALIDATE_RESET_PASSWORD_TOKEN = 'validateResetPasswordToken'
export const REQ_SAVE_NEW_PASSWORD = 'saveNewPassword'
export const REQ__ACTION_REDIRECT_NATIVE_APP_AUTH = 'redirectNativeAppAuth'

// Dashboard
export const REQ_DASHBOARD_CREATE_ACCOUNT = 'dashboardCreateAccount'
export const REQ_DASHBOARD_LOGIN_USER = 'dashboardLoginUser'
export const REQ_DASHBOARD_SEND_RESET_PASSWORD_LINK = 'dashboardSendResetPasswordLink'
export const REQ_DASHBOARD_VALIDATE_RESET_PASSWORD_TOKEN = 'dashboardValidateResetPasswordToken'
export const REQ_DASHBOARD_SAVE_NEW_PASSWORD = 'dashboardSaveNewPassword'
export const REQ_DASHBOARD_FETCH_APP_CONFIG = 'dashboardFetchAppConfig'
export const REQ_DASHBOARD_LOAD_AFFILIATE_PROFILE = 'dashboardLoadAffiliateProfile'
export const REQ_DASHBOARD_CREATE_PAYOUTS_ONBOARDING_URL = 'dashboardCreatePayoutsOnboardingUrl'
export const REQ_DASHBOARD_CREATE_PAYOUTS_LOGIN_URL = 'dashboardCreatePayoutsLoginUrl'
export const REQ_DASHBOARD_UPDATE_AFFILIATE_CODE = 'dashboardUpdateAffiliateCode'
export const REQ_DASHBOARD_FETCH_CLIENT_LIST = 'dashboardFetchClientList'
export const REQ_DASHBOARD_FETCH_CLIENT_DETAILS = 'dashboardFetchClientDetails'
export const REQ_DASHBOARD_REMOVE_CLIENT = 'dashboardRemoveClient'
export const REQ_DASHBOARD_SEND_CLIENT_PUSH_NOTIFICATION = 'dashboardSendClientPushNotification'
export const REQ_DASHBOARD_CREATE_APP_PRE_PURCHASE_PAYMENT_INTENT = 'dashboardCreateAppPrePurchasePaymentIntent'
export const REQ_DASHBOARD_LOGOUT_USER = 'dashboardLogoutUser'

// Logged In Page
export const REQ_CREATE_SUBSCRIPTION = 'purchaseSubscription'
export const REQ_CANCEL_SUBSCRIPTION = 'cancelSubscription'
export const REQ_LOGOUT_USER = 'logoutUser'
export const REQ_ENABLE_FREE_PREMIUM_ACCESS = 'enableFreePremiumAccess'
export const REQ_COMPLETE_ONBOARDING = 'completeOnboarding'
export const REQ_SAVE_SELF_CHECK_IN = 'saveSelfCheckIn'
export const REQ_GET_PERSONALIZED_NEUROFIT_EXERCISE = 'getPersonalizedNeuroFitExercise'
export const REQ_SAVE_NEUROFIT_EXERCISE_COMPLETION = 'saveNeuroFitExerciseCompletion'
export const REQ_TRIGGER_HRV_ANALYSIS_TASK = 'triggerHrvAnalysisTask'

export const REQ_GET_PERSONALIZED_LEARN_ARTICLES = 'getPersonalizedLearnArticles'
export const REQ_GET_ARTICLE_METADATA = 'getArticleMetadata'
export const REQ_SAVE_ARTICLE_START = 'saveArticleStart'
export const REQ_SAVE_ARTICLE_COMPLETION = 'saveArticleCompletion'
export const REQ_SAVE_ARTICLE_RATING = 'saveArticleRating'

export const REQ_GET_CLEAR_JOURNAL_CONFIG = 'getHistoricalClearJournalThreads'
export const REQ_SAVE_CLEAR_JOURNAL_PREFERENCES = 'saveClearJournalPreferences'
export const REQ_CREATE_CLEAR_JOURNAL_THREAD = 'createClearJournalThread'
export const REQ_FETCH_CLEAR_JOURNAL_THREAD_MESSAGE_HISTORY = 'fetchClearJournalThreadMessageHistory'
export const REQ_SEND_CLEAR_JOURNAL_CHAT_MESSAGE = 'sendClearJournalChatMessage'
export const REQ_SAVE_CLEAR_JOURNAL_CHAT_REACTION = 'saveClearJournalReaction'

export const REQ_INCREMENT_APP_REVIEW_REQUEST_COUNT = 'incrementAppReviewRequestCount'
export const REQ_SAVE_APP_FEEDBACK = 'saveAppFeedback'

export const REQ_CREATE_HRV_ANALYSIS_SIGNED_UPLOAD_URL = 'createHrvAnalysisSignedUploadUrl'
export const REQ_UPLOAD_HRV_READING_DATA = 'uploadHrvReadingData'
export const REQ_CREATE_HRV_ANALYSIS_TASK = 'createHrvAnalysisTask'
export const REQ_RETRIEVE_HRV_ANALYSIS_RESULTS = 'retrieveHrvAnalysisResults'

export const REQ_SAVE_UPDATED_ACCOUNT_DETAILS = 'saveUpdatedAccountDetails'

export const REQ_DELETE_ACCOUNT = 'deleteAccount'

export const REQ_GET_TRAINER_LINKED_STATUS = 'getTrainerLinkedStatus'
export const REQ_UPDATE_TRAINER_LINKED_STATUS = 'updateTrainerLinkedStatus'

export const REQ_SAVE_QUIZ_RESULTS = 'saveQuizResults'
export const REQ_FETCH_QUIZ_RESULTS = 'loadQuizResults'

export const REQ_SAVE_USER_DISCOVERY_QUIZ_RESULT = 'saveUserDiscoveryQuizResult'

export const REQ_SAVE_CONFIGURE_NOTIFICATIONS_SETTINGS = 'saveConfigureNotificationsSettings'

export const REQ_SAVE_RESET_PROGRAM = 'saveResetProgram'

export const REQ_SAVE_APP_EXPERIENCE_RATING = 'saveAppExperienceRating'

export const REQ_SAVE_PERSONALIZATION_ONBOARDING_RESULT = 'savePersonalizationOnboardingResult'

// Admin
export const REQ_ADMIN_GRANT_APP_ACCESS = 'adminGrantAppAccess'
export const REQ_ADMIN_CREATE_TRAINER_DASHBOARD_INVITE = 'adminCreateTrainerDashboardInvite'

// Creator Application Page
export const REQ_SUBMIT_CREATOR_APPLICATION = 'submitCreatorApplication'

// Async Actions
export const REQ_ACTION__FLASH_TOP_ERROR_MESSAGE = 'actionFlashTopErrorMessage'
export const REQ_ACTION__FLASH_BOTTOM_SUCCESS_MESSAGE = 'actionFlashBottomSuccessMessage'
export const REQ_ACTION__CONFIGURE_IN_APP_PURCHASES = 'configureInAppPurchases'
export const REQ_ACTION__REGISTER_FOR_NOTIFICATIONS = 'actionRegisterForNotifications'
export const REQ_ACTION__TRIGGER_APP_REVIEW = 'actionTriggerAppReview'
export const REQ_ACTION__TRIGGER_REQUEST_CAMERA_ACCESS = 'actionTriggerRequestCameraAccess'
export const REQ_ACTION__TRIGGER_OPEN_APP_SETTINGS = 'actionTriggerOpenAppSettings'
export const REQ__ACTION_PERIODICALLY_UPDATE_HRV_TASK_PROGRESS = 'periodicallyUpdateHrvTaskProgress'
export const REQ__ACTION_RESET_HRV_REQUESTS_STATE = 'resetHrvRequestsState'
export const REQ_ACTION__TRIGGER_COMPLETE_EXERCISE = 'triggerCompleteExercise'
export const REQ__ACTION_SHARE_ARTICLE = 'shareArticle'
export const REQ__ACTION_SHARE_APP = 'shareApp'
export const REQ__ACTION_UPDATE_APP_BADGE_COUNT = 'actionUpdateAppBadgeCount'
export const REQ__ACTION_AUTO_REFRESH_APP = 'actionAutoRefreshApp'
export const REQ__ACTION_PRESENT_DEEPLINKED_ARTICLE = 'presentDeepLinkedArticle'
export const REQ__ACTION_HANDLE_NOTIFICATION_ACTION_TYPE = 'handleNotificationActionType'
export const REQ__ACTION_OPEN_APP_RATING_URL = 'openAppRatingUrl'
export const REQ__ACTION_OPEN_APP_STORE_URL = 'openAppStoreUrl'
export const REQ__ACTION_OPEN_APP_STORE_SUBSCRIPTION_URL = 'openAppStoreSubscriptionUrl'
export const REQ__ACTION_HANDLE_APP_ACTION = 'handleAppAction'
export const REQ__ACTION_OPEN_LEGAL_URL = 'openLegalUrl'
export const REQ__ACTION_OPEN_SUPPORT_URL = 'openSupportUrl'

export const REQ__ACTION_APP_FOREGROUNDED_EXERCISE_IN_PROGRESS = 'appForegroundedExerciseInProgress'
export const REQ__ACTION_APP_BACKGROUNDED_EXERCISE_IN_PROGRESS = 'appBackgroundedExerciseInProgress'
export const REQ_ACTION__NATIVE_DO_CHECKOUT = 'nativeDoCheckout'

export const REQ__ACTION_REFRESH_NATIVE_APP = 'refreshNativeApp'

export const REQ__ACTION_PRESENT_MEMBERSHIP_MODAL = 'presentMembershipModal'
export const REQ__ACTION_PRESENT_DISCOVERY_QUIZ_MODAL = 'presentDiscoveryQuizModal'

export const REQ__ACTION_OPEN_CREATORS_URL = 'openCreatorsUrl'
export const REQ__ACTION_OPEN_CERTIFICATION_URL = 'openCertificationUrl'
export const REQ__ACTION_OPEN_CERTIFICATION_VSL_URL = 'openCertificationVslUrl'
export const REQ__ACTION_OPEN_BOOK_DOWNLOAD_URL = 'openBookDownloadUrl'
export const REQ__ACTION_OPEN_BOOK_URL = 'openBookUrl'
export const REQ__ACTION_OPEN_TRAINING_REGISTRATION_URL = 'openTrainingRegistrationUrl'
export const REQ__ACTION_OPEN_CERT_REGISTRATION_URL = 'openCertRegistrationUrl'

export const REQ_ACTION__UPDATE_APP_ICON = 'updateAppIcon'

export const REQ_SUBMIT_AZURE_CLUB_RSVP = 'submitAzureClubRsvp'

export const REQ_SUBMIT_WORKSHOP_DETAILS = 'submitWorkshopDetails'

export const REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS = 'submitCertificationVideoPageDetails'

export const REQ_SUBMIT_TRAINING_REGISTRATION_DETAILS = 'submitTrainingRegistrationDetails'

export const REQ_FETCH_CERTIFICATION_CODE_DETAILS = 'fetchCertificationCodeDetails'
