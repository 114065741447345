import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventGA,
  recordLinkClickAndRedirect,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  MinDesktopWidthPixels,
  subdomainUrls,
  getAffiliateCodeFromQueryString,
} from '../../utils'

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import agent from '../../agent'

import {
  GA_LABEL_LOADED_CERTIFICATION_VSL_CLICKED_START_VIDEO,
  GA_LABEL_LOADED_CERTIFICATION_VSL_CLICKED_ENROLL_NOW,
} from '../../constants/gaEventLabels'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
} from '../../constants/requestStates'


const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class TrainerCertificationVideoDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSettingsModal: false,
      hasScrolled: true,
      iFrameLoaded: false,
      signUpRef: React.createRef(),
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      firstName: "",
      emailAddress: ""
    }
  }

  handleOnClickLearnMore = (event) => {
    //.current is verification that your element has rendered
    if (this.learnMoreRef.current){
        this.learnMoreRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  handleOnScrollToCheckout = () => {
    if (this.checkoutRef.current) {
        this.checkoutRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`
      const hasScrolled = true

      const updatedState = {headerBackgroundColor, hasScrolled, heroOverlayColor, dropShadowColor, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", hasScrolled: true, heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  componentWillMount() {
    const certificationUniqueCode = getAffiliateCodeFromQueryString(window.location.href)

    this.setState({certificationUniqueCode})
  }

  async componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)

    const script = document.createElement("script");

    script.src = "/assets/html/certification-vsl-video.js";
    script.async = true;

    document.body.appendChild(script);

    await initializePixelIfAvailable()
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
    } = this.props


    const {show, certificationUniqueCode, iFrameLoaded, hasScrolled, learnMoreRef, showSettingsModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const videoWidth = Math.min(innerWidth * 0.85, 600)
    const videoAspectRatio = 1080 / 1920

    const isMobile = innerWidth <= MinDesktopWidthPixels

    return (
      <div 
        id={"certification-page-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={containerStyle}
      >
        <div style={{textAlign: "left", width: "100vw", minHeight: innerHeight, backgroundColor: "#FFFFFF"}}>
          <div style={{display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}} ref={elem => this.signUpRef = elem}>
            <div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: 60}}>
                <div style={{width: "85%", maxWidth: 600}}>
                  <div style={{display: "inline-block", fontSize: "min(8vw, 36px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", marginTop: `calc(4vh + ${safeAreaInsets.top}px)`, textTransform: "uppercase"}}>
                    {`View Training Now`}
                  </div>
                </div>
              </div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 600}}>
                  {!!(certificationUniqueCode) && (
                    <div style={{display: "inline-block", fontSize: "min(6vw, 26px)", fontStyle: "italic", textAlign: "left", fontFamily: "Futura Light", marginRight: "0.3em", marginTop: `calc(2vh)`}}>
                      {`A unique offer code for $200 off has been sent to your inbox.`}
                    </div>
                  )}
                  <div style={{display: "inline-block", fontSize: "min(6vw, 26px)", fontStyle: "italic", textAlign: "left", fontFamily: "Futura Light", marginRight: "0.3em", marginTop: `calc(2vh)`}}>
                    {!!(certificationUniqueCode) && (
                      <span style={{fontFamily: "Futura"}}>{`NEXT STEP:`}</span>
                    )}
                    <span>{` Watch this 8 minute training now to quickly improve client results and retention.`}</span>
                  </div>
                </div>
              </div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginTop: `calc(2vh)`}}>
                <div style={{width: videoWidth, height: videoAspectRatio * videoWidth, maxWidth: 600, border: "3px #000000 solid", overflow: "hidden"}} className={iFrameLoaded ? "" : "video-loading-background-animation"}>
                  <div id="vidalytics_embed_QhC0VP7Xe3az4kaQ" style={{width: videoWidth, height: videoAspectRatio * videoWidth}}></div>
                </div>
              </div>
              <div style={{display: "flex", width: "100%", justifyContent: "center", marginTop: "2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(5vw, 20px)", color: "#777", fontStyle: "italic", textAlign: "center", fontFamily: "Futura", marginRight: "0.3em", marginTop: `calc(2vh)`}}>
                  {`READY TO GET STARTED?`}
                </div>
              </div>
              <div style={{display: "flex", width: "100%", justifyContent: "center", marginTop: "1vh"}}>
                <button
                  style={{display: "flex", alignItems: "top", justifyContent: "center", width: "85%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", color: "#ffffff", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                  onClick={() => {
                    const redirectPath = !!(certificationUniqueCode) ? `/certification?c=${certificationUniqueCode}` : "/certification"
                    recordLinkClickAndRedirect(GA_LABEL_LOADED_CERTIFICATION_VSL_CLICKED_ENROLL_NOW, redirectPath, true/*sameTab*/)
                  }}
                >
                  {"VIEW CERTIFICATION"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw"}}>
          <div style={{padding: 15, paddingBottom: 15 + safeAreaInsets.bottom, backgroundColor: "#FFFFFF", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
            <div style={{display: "inline-block"}}>
              <span style={{color: "#c1c4d2"}}>
                {"© NEUROFIT 2023"}
              </span>
              <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                {"•"}
              </span>
              <span style={{color: "#c1c4d2"}}>
                {"ALL RIGHTS RESERVED"}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TrainerCertificationVideoDetails