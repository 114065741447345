import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionAboutBaseUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
} from '../utils'

import Helmet from './common/Helmet'

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class AboutPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        learnMoreRef: React.createRef(),
        headerColor: "transparent",
        dropShadowColor: "transparent",
        show: true,
        showIframe: false,
     }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      i18n,
      isIOSDevice,
      isAndroidDevice,
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showIframe} = this.state

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"About NeuroFit - Nervous System Dysregulation App"}
          description={"Learn more about NeuroFit, the app that balances nervous system dysregulation, available for iOS and Android."}
          href={ProductionAboutBaseUrl}
        />
        <CSSTransition
          in={show}
          timeout={400}
          classNames={"fade-in-400"}
          onExited={() => {}}
        >
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
            <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
            <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: "min(20px, 4%)", paddingBottom: 12, backgroundColor: "white", zIndex: 1000}}>
              <NeuroFitNamedLogoSvg
                logoSize={"min(5.75vw, 24px)"}
                color={"#000000"}
                onClick={() => window.location = "/"}
              />
              {!isNative && (
                <div style={{display: "flex", alignItems: "center"}}>
                  <a
                    href={"/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{fontFamily: "Futura Medium", textDecoration: "none", fontSize: "min(4vw, 15px)", textTransform: "uppercase", color: "#000000", cursor: "pointer"}}
                  >
                    {"Home"}
                  </a>
                </div>
              )}
            </div>
            <div 
              id={"landing-page-container"}
              className={"disable-scrollbars"}
              ref={elem => this.scrollingContainerRef = elem}
              style={containerStyle}
            >
              <div style={{textAlign: "left", width: "100vw", minHeight: innerHeight}}>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginTop: "calc(60px + 3vh)"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <h1 style={{fontSize: "min(6.5vw, 35px)", textAlign: "left", fontFamily: "Futura"}}>
                      {"Regulate, Balance & Optimize your nervous system in 6 weeks.".toUpperCase()}
                    </h1>
                    <h3 style={{marginTop: 20, fontSize: "min(5.5vw, 22px)", textAlign: "left", fontFamily: "Futura Light"}}>
                      <div style={{display: "inline-block"}}>
                        {"Through daily nervous system check-ins, biometric measurements using only a smartphone camera, and tailored nervous system exercises, NeuroFit coaches you to optimize your nervous system in just 6 weeks."}
                      </div>
                    </h3>
                    <h3 style={{marginTop: 20, fontSize: "min(5.5vw, 22px)", textAlign: "left", fontFamily: "Futura Light"}}>
                      <div style={{display: "inline-block"}}>
                        {"NeuroFit is co-led by Loren & Andrew Hogue, in South Yarra, Australia."}
                      </div>
                    </h3>
                    <h3 style={{marginTop: 20, fontSize: "min(5.5vw, 22px)", textAlign: "left", fontFamily: "Futura Light"}}>
                      <div style={{display: "inline-block"}}>
                        {"Available now on iOS and Android."}
                      </div>
                    </h3>
                    {(isIOSDevice || isAndroidDevice) ? (
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <button
                          style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                          onClick={() => {
                            recordLinkClickGA("Universal App Download Link")
                            window.open(NeuroFitUniversalAppStoreLink, "_blank", "noopener noreferrer nofollow")
                          }}
                        >
                          {isIOSDevice ? (
                            <AppleSvg />
                          ) : (isAndroidDevice ? (
                            <AndroidSvg />
                          ) : null)}
                          <span>
                            {"DOWNLOAD NOW"}
                          </span>
                        </button>
                      </div>
                    ) : (
                      <div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                          <button
                            style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                            onClick={() => {
                              recordLinkClickGA("Universal App Download Link")
                              window.open(NeuroFitAppleAppStoreLink, "_blank", "noopener noreferrer nofollow")
                            }}
                          >
                            <AppleSvg />
                            <span>
                              {"DOWNLOAD IOS"}
                            </span>
                          </button>
                        </div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                          <button
                            style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 32, color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                            onClick={() => {
                              recordLinkClickGA("Universal App Download Link")
                              window.open(NeuroFitGooglePlayStoreLink, "_blank", "noopener noreferrer nofollow")
                            }}
                          >
                            <AndroidSvg />
                            <span>
                              {"DOWNLOAD ANDROID"}
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                    <div style={{fontSize: "min(6.5vw, 35px)", textAlign: "center", fontFamily: "Futura", marginTop: 64, marginRight: "0.3em", textTransform: "uppercase"}}>
                      {"Nervous System Fitness™"}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw"}}>
                <div style={{marginTop: 15, padding: 15, fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                  <div style={{display: "inline-block"}}>
                    <span style={{color: "#c1c4d2"}}>
                      {"© NEUROFIT 2023"}
                    </span>
                    <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                      {"•"}
                    </span>
                    <span style={{color: "#c1c4d2"}}>
                      {"ALL RIGHTS RESERVED"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default AboutPage;