import React from 'react'


const CompletionCheckIcon = ({
  isComplete,
  checkboxBaseClassName,
  checkColorIncomplete,
  checkColorComplete,
  marginTop,
  animateCheck
}) => {
  const baseClassName = checkboxBaseClassName || "checkbox-plan-button"
  const checkboxClassName = `${baseClassName} ${isComplete ? baseClassName + "--active" : ""}`
  return (
    <svg
      className={checkboxClassName}
      style={{marginTop: marginTop || 0, transition: "background-color 150ms linear"}}
      disabled
      // This element is purely decorative so
      // we hide it for screen readers
      aria-hidden="true"
      viewBox="0 0 15 11"
      fill="none"
    >
      <path
        d="M1 4.5L5 9L14 1"
        className={animateCheck ? "checkmark-line-animation" : ""}
        strokeWidth="2"
        strokeDasharray={animateCheck ? "50px, 50px" : ""}
        strokeDashoffset={animateCheck ? "-100px" : ""}
        stroke={isComplete ? (checkColorComplete || "#fff") : (checkColorIncomplete || "#fff")}
      />
    </svg>
  )
}


export default CompletionCheckIcon;