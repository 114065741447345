import React, {lazy} from 'react';
// import ReactGA from 'react-ga';
import queryString from 'query-string'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {history} from './history';

import * as dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(customParseFormat)


export const isProduction = (process.env.NODE_ENV === 'production')

export const HEADER_HEIGHT_PIXELS = 70
export const FOOTER_HEIGHT_PIXELS = 70
export const MAX_TEST_RESPONSE_BUFFER_SIZE = 5
export const MS_PER_HOUR = 1000 * 60 * 60

export const MaxResponseLength = 1000

let SharedMixpanelInstance;

const getMixpanel = async () => {
  while (!SharedMixpanelInstance) {
    await delay(1000)
  }
  return SharedMixpanelInstance
}
// Google Analytics Utils
export const initializeGA = async (disableCookies=false) => {
  try {
    if (isProduction && !SharedMixpanelInstance) {
      // ReactGA.initialize('UA-240315789-1', gaConfig);
      const MixpanelToken = "1187afc3c6a3b394ad3824b9eab9f785"
      const mixpanelInstance = (await import("mixpanel-browser")).default
      mixpanelInstance.init(MixpanelToken, {debug: (!(isProduction)), disable_persistence: disableCookies})
      SharedMixpanelInstance = mixpanelInstance
    }
  } catch (err) {
    console.error(err)
  }
}

export const initializeDashboardGA = async (disableCookies=false) => {
  try {
    if ((isProduction || true) && !SharedMixpanelInstance) {
      // ReactGA.initialize('UA-240315789-1', gaConfig);
      const MixpanelToken = "c61a7cc3ea29dd051d312a4227b59a63"
      const mixpanelInstance = (await import("mixpanel-browser")).default
      mixpanelInstance.init(MixpanelToken, {debug: (!(isProduction)), disable_persistence: disableCookies})
      SharedMixpanelInstance = mixpanelInstance
    }
  } catch (err) {
    console.error(err)
  }
}

export const pageViewGA = () => {
  try {
    if (isProduction) {
      // ReactGA.pageview('/');
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordEventGA = async (eventType, eventName, properties={}) => {
  try {
    if (isProduction) {
      const days_since_signup = Math.floor(getDaysSinceTimestamp(UserCreatedAtUnix))
      const weeks_since_signup = Math.floor(getWeeksSinceTimestamp(UserCreatedAtUnix))
      const months_since_signup = Math.floor(getMonthsSinceTimestamp(UserCreatedAtUnix))
      const is_premium_user = IsPremiumUser
      const days_since_premium = IsPremiumUser ? Math.floor(getDaysSinceTimestamp(UserStartedPremiumAtUnix)) : -1
      const weeks_since_premium = IsPremiumUser ? Math.floor(getWeeksSinceTimestamp(UserStartedPremiumAtUnix)) : -1
      const months_since_premium = IsPremiumUser ? Math.floor(getMonthsSinceTimestamp(UserStartedPremiumAtUnix)) : -1
      const mixpanel = await getMixpanel()
      mixpanel.track(eventName, {event_type: eventType, ...properties, days_since_signup, weeks_since_signup, months_since_signup, is_premium_user, days_since_premium, weeks_since_premium, months_since_premium})
      // ReactGA.event({
      //   category: eventType,
      //   action: eventName,
      //   label: "Analytics Event",
      //   value: 1
      // });
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordFunnelEventGA = (eventName) => {
  try {
    if (isProduction) {
      recordEventGA("New User Funnel", eventName)
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordPurchaseFunnelEventGA = (eventName, productId) => {
  try {
    recordFunnelEventGA(eventName)
    if (!!(productId)) {
      recordFunnelEventGA(`${eventName}: ${productId}`)
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordStoryReadEvent = (storyKey) => {
  return recordEventGA("Story Read", storyKey)
}

export const recordLinkClickGA = (eventName) => {
  try {
    return recordEventGA("Link Click", eventName);
  } catch (err) {
    console.error(err)
  }
}

export const recordLinkClickAndRedirect = async (eventName, redirectUrl, sameTab) => {
  if (isProduction) {
    try {
      const mixpanel = await getMixpanel()
      mixpanel.track(eventName, {}, () => {
        if (!!(sameTab)) {
          window.location = redirectUrl
        } else {
          window.open(redirectUrl, "_blank", "noopener nofollow")
        }
      })
    } catch (err) {
      console.error(err)
    }
  } else {
    if (!!(sameTab)) {
      window.location = redirectUrl
    } else {
      window.open(redirectUrl, "_blank", "noopener nofollow")
    }
  }
}

export const recordSignupFunnelEventGA = (eventName) => {
  try {
    return recordFunnelEventGA(eventName);
  } catch (err) {
    console.error(err)
  }
}

export const recordFunnelEventResultGA = (eventName, properties) => {
  try {
    if (isProduction) {
      recordEventGA("Event Result", eventName, properties)
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordSessionEventGA = (sessionType) => {
  try {
    return recordEventGA("Session", sessionType)
  } catch (err) {
    console.error(err)
  }
}

let ENABLE_PIXEL = false;
let ReactPixel;

export const initializePixelIfAvailable = async () => {
  try {
    if (isProduction) {
      const {name} = getTimeZoneOffset()
      ENABLE_PIXEL = !name.startsWith("Europe")
      // GDPR-Compliant
      if (ENABLE_PIXEL) {
        ReactPixel = require('react-facebook-pixel').default
        ReactPixel.init("2022945354569015", {}, {autoConfig: true, debug: false});
        ReactPixel.pageView(); // For tracking page view
      }
    }
  } catch (err) {
    console.error(`Error Loading Pixel: ${err}`)
  }
}

// TODO: Record Events: Lead, AddPaymentInfo, InitiateCheckout, Purchase
export const recordPixelEventIfAvailable = (eventName, productId) => {
  const productPricesUsd = {
    "book": 0.99,
    "book_bundle": 35.99,
    "certification": 697.00,
    "neurofit_app_pre_purchase_3_months": 35.99,
    "neurofit_app_pre_purchase_12_months": 124.99,
  }
  const productValueUsd = productPricesUsd[productId] || 697.00
  if (isProduction && ENABLE_PIXEL && !!(ReactPixel)) {
    ReactPixel.track(eventName, {currency: "USD", value: productValueUsd})
  }
}


export let UserCreatedAtUnix = -1
export let IsPremiumUser = false
export let UserStartedPremiumAtUnix = -1
export let UserUuid = ""
export const SetUserFieldsUnix = (createdAtUnix, isPremiumUser, startedPremiumAtUnix) => {
  UserCreatedAtUnix = createdAtUnix
  IsPremiumUser = isPremiumUser
  UserStartedPremiumAtUnix = startedPremiumAtUnix
}

export const configureAnalyticsUser = async ({uuid="",isPremiumUser=false, hasStartedFreeTrial=false, hasConvertedPaidSubscription=false, hasActiveSubscription=false, activeSubscriptionId="", language='en', createdAtUnix=-1, startedPremiumAtUnix=-1, experimentFlags={}}) => {
  try {
    if (isProduction) {
      UserUuid = uuid
      let userProperties = {
        'neurofit_uuid': uuid,
        'has_valid_session': true,
        'is_premium_user': isPremiumUser,
        'has_started_free_trial': hasStartedFreeTrial,
        'has_converted_paid_subscription': hasConvertedPaidSubscription,
        'has_active_subscription': hasActiveSubscription,
        'active_subscription_id': activeSubscriptionId,
        'language': language,
        'created_at_unix': createdAtUnix,
        'user_retention_days': Math.floor(getDaysSinceTimestamp(createdAtUnix)),
        'user_retention_weeks': Math.floor(getWeeksSinceTimestamp(createdAtUnix)),
        'user_retention_months': Math.floor(getMonthsSinceTimestamp(createdAtUnix)),
        ...experimentFlags
      }
      if (isPremiumUser) {
        userProperties.days_since_premium = Math.floor(getDaysSinceTimestamp(startedPremiumAtUnix))
        userProperties.weeks_since_premium = Math.floor(getWeeksSinceTimestamp(startedPremiumAtUnix))
        userProperties.months_since_premium = Math.floor(getMonthsSinceTimestamp(startedPremiumAtUnix))

      }
      const mixpanel = await getMixpanel()
      mixpanel.identify(uuid)
      mixpanel.people.set(userProperties)
      mixpanel.register(userProperties)
    }
  } catch (err) {
    console.error(err)
  }
}

export const identifyCertificationAnalyticsWebUser = async (uuid) => {
  try {
    if (isProduction) {
      const mixpanel = await getMixpanel()
      mixpanel.identify(uuid)
    }
  } catch (err) {
    console.error(err)
  }
}

export const configureAnalyticsAnonymousUser = async ({experimentFlags={}}) => {
  try {
    if (isProduction) {
      let userProperties = {
        ...experimentFlags
      }
      
      const mixpanel = await getMixpanel()
      mixpanel.people.set(userProperties)
      mixpanel.register(userProperties)
    }
  } catch (err) {
    console.error(err)
  }
}

export const setAnalyticsUserProperty = async (eventName, userProperties) => {
  try {
    if (isProduction) {
      if (!!(UserUuid)) {
        const mixpanel = await getMixpanel()
        mixpanel.identify(UserUuid)
        mixpanel.people.set(userProperties);
        recordEventGA("User Properties", eventName, userProperties)
      }
    }
  } catch (err) {
    console.error(err)
  }
}

export const configureAnalyticsSuperProperties = async (superProperties, disableCookies=false) => {
  try {
    if (isProduction) {
      const mixpanel = await getMixpanel()
      mixpanel.register(superProperties, {persistent: disableCookies})
    }
  } catch (err) {
    console.error(err)
  }
}

export const setAdvertisementReferralUserProperty = async (adIdentifier) => {
  const mixpanel = await getMixpanel()
  mixpanel.people.set({adIdentifier});
}

export const resetAnalyticsUser = async () => {
  try {
    if (isProduction) {
      const mixpanel = await getMixpanel()
      mixpanel.reset();
    }
  } catch (err) {
    console.error(err)
  }
}

export const isGDPRSensitive = (() => {
  const timeZoneName = dayjs.tz.guess()
  return timeZoneName.startsWith("Europe")
})()

export const NeuroFitAppleAppStoreLink = "https://apps.apple.com/us/app/neurofit-balance-body-mind/id1630278170"
export const NeuroFitGooglePlayStoreLink = "https://play.google.com/store/apps/details?id=com.neurofit.app"
export const NeuroFitUniversalAppStoreLink = "https://neurofit.link/install"
export const CertificationCheckoutLink = "https://neurofitapp.mykajabi.com/offers/pUbMMqot/checkout"
export const CertificationThreePaymentCheckoutLink = "https://neurofitapp.mykajabi.com/offers/7vVLqkif/checkout"
export const KajabiCourseUrl = "https://neurofitapp.mykajabi.com/library"
export const AmazonNeuroFitBookUrl = "https://www.amazon.com/dp/B0BRFNYKD4/"
export const EmbodiedCeoCourseLink = 'https://embodiedceo.life'
export const WellAndGoodArticleLink = "https://www.wellandgood.com/nervous-system-exercises-app/"
export const MamamiaArticleLink = "https://www.mamamia.com.au/overcome-burnout/"
export const DailyMailArticleLink = "https://dailymail.co.uk/femail/health/article-11720199/High-flying-lawyer-Loren-Hogue-reveals-overcame-severe-burnout-regulate-nervous-system.html"

// User Agent Utils
export const getUserAgent = () => {
  return navigator.userAgent || navigator.vendor || window.opera;
}

export const isIOSDevice = () => {
  return (/iPad|iPhone|iPod/.test(getUserAgent()) && !window.MSStream)
}

export const isFirefox = (() => {
  return /firefox/i.test(getUserAgent())
})()

export const isIOSSafari = () => {
  if (!isIOSDevice()) {
    return false
  }
  const ua = window.navigator.userAgent;
  return (!!ua.match(/WebKit/i) && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i))
}

export const isAndroidDevice = () => {
  return (/android/i.test(getUserAgent()))
}

export const runningAsApp = () => {
  if (isIOSDevice()) {
    return (window.navigator.standalone === true)
  } else {
    // Android
    return window.matchMedia('(display-mode: standalone)').matches
  }
}

export const isIOSStandalone = () => {
  return isIOSDevice() && (window.navigator.standalone === true)
}

export const baseDynamicLinkDomain = "neurofit.link"
export const baseDomain = "neurofit.app"
export const baseAppDomain = `native.${baseDomain}`
export const baseDashboardDomain = `dashboard.${baseDomain}`
export const baseAppHost = isProduction ? baseDomain : "localhost:4100"
export const baseNativeAppHost = isProduction ? baseAppDomain : "localhost:4101"
export const baseDashboardAppHost = isProduction ? baseDashboardDomain : "localhost:4102"

export const subdomainHosts = {
  baseAppHost,
  baseAppDomain,
  baseDashboardAppHost,
}

const httpsPrefix =  "https://"
const httpPrefix = isProduction ? httpsPrefix : "http://"

export const DefaultCdnBaseUrl = `https://cdn.${baseDomain}`
export const DefaultQuizResultCdnBaseUrl = `https://quiz-results.${baseDomain}`
export const DefaultCertificationCodesCdnBaseUrl = `https://certification-codes.${baseDomain}`
export const ProductionBaseUrl =  `https://${baseDomain}`
export const ProductionBlogBaseUrl = `https://${baseDomain}/learn`
export const ProductionAboutBaseUrl = `https://${baseDomain}/about`
export const ProductionLinksBaseUrl = `https://${baseDomain}/links`
export const ProductionCreatorsUrl = `https://${baseDomain}/creators`
export const ProductionCertificationUrl = `https://${baseDomain}/certification`
export const ProductionCourseUrl = `https://${baseDomain}/course`
export const ProductionSupportBaseUrl = `https://${baseDomain}/support`
export const ProductionQuizBaseUrl = `https://${baseDomain}/quiz`
export const ProductionLegalUrl = `https://${baseAppDomain}/legal/`
export const ProductionAzureClubRsvpUrl = `https://${baseDomain}/azureclub`
export const ProductionWorkshopsUrl = `https://${baseDomain}/workshops`
export const ProductionBookUrl = `https://${baseDomain}/book`
export const ProductionBookDownloadUrl = `https://${baseDomain}/book_download/9lWPpvs9UjtT54efP2FoLWClzkx2Ap`
export const ProductionBalanceUrl = `https://${baseDomain}/balance`
export const ProductionTrainingRegistrationUrl = `https://${baseDomain}/register`
export const ProductionCertificationRegistrationUrl = `https://${baseDomain}/cert-offer`
export const ProductionCertificationVslUrl = `https://${baseDomain}/vsl`
export const NeuroFitCourseCheckoutPage = `${httpPrefix}${baseAppHost}/course/checkout`
export const NeuroFitCertificationCheckoutPage = `${httpPrefix}${baseAppHost}/certification/checkout`

export const AppName = "NeuroFit"
export const AppDescription = "Through daily check-ins, biometric measurements via your phone's camera, and tailored exercises, NeuroFit balances the nervous system, mind, and spirit."

export const StripePublishableKey = (isProduction) ? 
"pk_live_51LK7yaJv6nSiqyNcavyFRxoyYm6OCKQlZF0U6UF423ccOiG4NYnbxhGH96LhnSU88TOuZekH0VSdcxR1vRSubP1g00B3ADHJNx" :
"pk_test_51LK7yaJv6nSiqyNc8sPaLLTiRYnb19Qw3i76ZHbjYdnArggu7BRPPxftAYyneU4lqHJt2RuRrEuiMjZYCHJVsc7h003980UO5c"


export const baseAppUrl = httpPrefix + baseAppHost
export const baseNativeAppUrl = httpPrefix + baseNativeAppHost
export const baseLegalUrl = httpsPrefix + "legal." + baseDomain
export const baseDashboardAppUrl = httpPrefix + baseDashboardAppHost
export const baseDynamicLinkUrl = httpsPrefix + baseDynamicLinkDomain

export const subdomainUrls = {
  baseAppUrl,
  baseNativeAppUrl,
  baseDynamicLinkUrl,
  baseDashboardAppUrl,
  baseLegalUrl
}

export const getPublicArticleUrl = (articleKey) => `${ProductionBaseUrl}/learn/${articleKey}/`

export const toggleBodyScroll = (enable) => {
    if (enable) {
      document.body.classList.remove("noscroll")
    } else {
      document.body.classList.add("noscroll")
    }
}


// Default to this in case initial page load (before i18n is fetched) fails.
export let i18n = {
  error_page_load_failed: "Error: Page Load Failed.",
  retry: "Retry",
}
export let language = "en"

export const SetGlobalI18N = (updatedI18N, updatedLanguage) => {
  i18n = updatedI18N
  language = updatedLanguage
}

export const getLocalizedErrorMessageFromRequestResult = (req) => {
  return !!(req.error) ? (i18n[req.error.error_code] || req.error.error_message) : ""
}

export const formatContentDuration = (durationSeconds) => {
  if (durationSeconds < 0) {
    return "0:00"
  }
  const seconds = (durationSeconds % 60)
  return Math.floor(durationSeconds / 60) + ":" + ((seconds < 10) ? "0" : "") + seconds
}

export const hideLoadingScreen = () => {
  let loadingScreenElem = document.getElementById("loading-screen")
  if (!!(loadingScreenElem)) {
    loadingScreenElem.style.display = "none"
    // loadingScreenElem.parentNode.removeChild(loadingScreenElem);
  }
}

export const showLoadingScreen = () => {
  let loadingScreenElem = document.getElementById("loading-screen")
  if (!!(loadingScreenElem)) {
    loadingScreenElem.style.display = "block"
    // loadingScreenElem.parentNode.removeChild(loadingScreenElem);
  }
}

export const loadingScreenIsVisible = () => {
  const loadingScreenElem = document.getElementById("loading-screen")
  return !!(loadingScreenElem) && loadingScreenElem.style.display !== "none"
}

export const reloadApp = async () => {
  showLoadingScreen()
  window.location.reload(true/*forcedReload*/)
}

export const removeHttpsPrefixFromLink = link => !!(link) ? link.replace(/^https?:\/\//,'') : ""

export const setWindowUrlToBase = () => {
  history.push("/");
}

export const getAdvertisementIdFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.a
}

export const isAppWarmup = (() => {
  try {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    return params.w === "1"
  } catch (e) {
    return false
  }
})()


export const getAffiliateIdFromQueryString = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.t || ""
}

export const getAffiliateCodeFromQueryString = (url) => {
  const inputUrlObject = new URL(url);
  const params = new Proxy(new URLSearchParams(inputUrlObject.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params.c || ""
}

const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const currentTime = () => dayjs()
export const currentTimeLocal = () => dayjs().local()
export const time = (s, format) => dayjs(s, format)
export const get7AMLocalTimeWithTimezone = () => dayjs().tz(dayjs.tz.guess()).format('[7:00 am] z')
export const getTimeZoneOffset = () => ({
  name: dayjs.tz.guess(),
  offset: dayjs().tz(dayjs.tz.guess()).format('Z')
})

export const getTimeOfDay = (timestampSeconds) => {
  const hr = (!!(timestampSeconds)) ? dayjs.unix(timestampSeconds).local().hour() : dayjs().hour()
  if (hr < 12) {
    return "morning"
  } else if (hr < 17) {
    return "afternoon"
  } else {
    return "evening"
  }
}

export const readableDate = dateString => {
    return dayjs(dateString, "YYYY-MM-DD").format('M/D')
}

export const getTimeOfDayStringAsMinutes = timeOfDayString => {
  if (!timeOfDayString) {
    return undefined
  }
  const [h, m] = timeOfDayString.split(":").map( val => parseInt(val) );
  return (60 * h) + m
}

export const getMinutesAsTimeOfDayString = minutes => dayjs.duration({hours: Math.floor(minutes / 60), minutes: minutes % 60}).format("HH:mm")

export const isTodayUnix = timestampSeconds => {
  const date = dayjs().local()
  const inputDate = dayjs.unix(timestampSeconds).local()
  return (date.date() === inputDate.date()) && (date.month() === inputDate.month()) && (date.year() === inputDate.year())
} 

export const convertToDate = timestampSeconds => {
  return dayjs.unix(timestampSeconds).local().format("MMM D")
}

export const convertToArticleDate = timestampSeconds => {
  return dayjs.unix(timestampSeconds).local().format("MMM D, YYYY")
}

export const formatDurationSeconds = durationSeconds => {
  return dayjs.duration({seconds: durationSeconds}).format("m:ss")
}

export const formatReadableUnixTimestamp = timestampSeconds => {
  return dayjs.unix(timestampSeconds).format("dddd, MMMM D, YYYY")
}

export const formatReadableUnixTimestampMins = timestampSeconds => {
  return dayjs.unix(timestampSeconds).format("MMM D, h:mm A").toUpperCase()
}

export const getNextEnrollmentCloseUnixTimestamp = () => {
  const secondsInWeek = 86400 * 7
  const baseEnrollmentCloseUnixTimestamp = 1685606400
  const currentTimestampUnix = dayjs().unix()
  let outputTimestamp = baseEnrollmentCloseUnixTimestamp
  // while (outputTimestamp < currentTimestampUnix) {
  //   outputTimestamp += secondsInWeek
  // }
  const is_closed = outputTimestamp < currentTimestampUnix
  return {date: dayjs.unix(outputTimestamp).toDate(), is_closed}
}


export const getDiscountCodeExpirationMetadata = (expirationTimestamp) => {
  const currentTimestampUnix = dayjs().unix()

  const is_expired = expirationTimestamp < currentTimestampUnix
  return {expiration_date: dayjs.unix(expirationTimestamp).toDate(), is_expired}
}


export const hoursSinceUnixTimestamp = (timestampSeconds, floatResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'hour', floatResult)
}

export const getFormattedDateDaysFromNow = (n) => {
  return dayjs().add(n, 'day').format("MMMM D, YYYY")
}

export const getDateStringDaysFromNow = (date, n) => {
  return date.add(n, 'day').format("MM-DD-YYYY")
}


export const getTimestampPlusSeconds = (time, seconds) => time.add(seconds, 'second')

export const getLocalDaysSinceTimestamp = (timestampSeconds) => {
  const currentDate = currentTime().local().set('hour', 0).set('minute', 0).set('second', 0)
  const timestampDate = dayjs.unix(timestampSeconds).local().set('hour', 0).set('minute', 0).set('second', 0)

  return Math.floor(currentDate.diff(timestampDate, 'day'))
}

export const getLocalWeeksSinceTimestamp = (timestampSeconds) => {
  const currentDate = currentTime().local().set('hour', 0).set('minute', 0).set('second', 0)
  const timestampDate = dayjs.unix(timestampSeconds).local().set('hour', 0).set('minute', 0).set('second', 0)

  return Math.floor(currentDate.diff(timestampDate, 'week'))
}

export const getSecondsSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'second', decimalResult)
}

export const getMinutesSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'minute', decimalResult)
}

export const getHoursSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'hour', decimalResult)
}

export const getDaysSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'day', decimalResult)
}

export const getWeeksSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'week', decimalResult)
}


export const getMonthsSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'month', decimalResult)
}

export const getYearsSinceTimestamp = (timestampSeconds, decimalResult=false) => {
  return currentTime().diff(dayjs.unix(timestampSeconds), 'year', decimalResult)
}

export const getAbbreviatedDurationSinceTimestamp = (timestampSeconds) => {
  if (getYearsSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getYearsSinceTimestamp(timestampSeconds))}Y`
  } else if (getMonthsSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getMonthsSinceTimestamp(timestampSeconds))}M`
  } else if (getWeeksSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getWeeksSinceTimestamp(timestampSeconds))}W`
  } else if (getDaysSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getDaysSinceTimestamp(timestampSeconds))}D`
  } else if (getHoursSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getHoursSinceTimestamp(timestampSeconds))}H`
  } else if (getMinutesSinceTimestamp(timestampSeconds) >= 1) {
    return `${Math.floor(getMinutesSinceTimestamp(timestampSeconds))} MIN.`
  } else {
    return `${Math.floor(getSecondsSinceTimestamp(timestampSeconds))}S`
  }
}

export const getCurrentYear = () => {
  return currentTime().format("YYYY")
}

export const getIntSecondsSinceTime = (time) => {
  return Math.floor(currentTime().diff(time, 'second'))
}

export const shouldAutoRefreshApp = (appLoadedAt) => {
  const currTime = currentTime()
  const format = 'DD/MM/YYYY'
  return (currTime.diff(appLoadedAt, 'hour', true) >= 1.0) || (currTime.format(format) !== appLoadedAt.format(format))
}

/////////////
// Cookies //
/////////////
const ACCOUNT_CREATION_NONCE = 'account_creation_nonce'
const CSRF_TOKEN_KEY = 'csrf_token'
const DASHBOARD_CSRF_TOKEN_KEY = 'dashboard_csrf_token'
export const INVITE_CODE_LOCAL_STORAGE_KEY = "alma__local_storage_b2b_invite_code"
const COOKIE_MISSING = "_missing"
const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)===' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return COOKIE_MISSING;
}

export const getCsrfTokenFromCookies = () => getCookie(CSRF_TOKEN_KEY)
export const getCsrfTokenCookieIsPresent = () => {
  const c = getCookie(CSRF_TOKEN_KEY)
  return (c !== COOKIE_MISSING) && !!(c)
}

export const getDashboardCsrfTokenFromCookies = () => getCookie(DASHBOARD_CSRF_TOKEN_KEY)
export const getAppleNonceTokenFromCookies = () => getCookie(ACCOUNT_CREATION_NONCE)

export const delay = (ms) => new Promise(res => setTimeout(res, ms))

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export const resizeInnerHeight = () => {
   const innerHeight = document.body.clientHeight || window.innerHeight;
   document.body.height = innerHeight
   return innerHeight
}

export const resizeInnerWidth = () => {
  const innerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  document.body.width = innerWidth
  return innerWidth
}

export const asyncCallWithTimeout = async (asyncPromise, timeLimit) => {
    let timeoutHandle;

    const timeoutPromise = new Promise((_resolve, reject) => {
        timeoutHandle = setTimeout(
            () => reject(new Error('Async call timeout limit reached')),
            timeLimit
        );
    });

    return Promise.race([asyncPromise, timeoutPromise]).then(result => {
        clearTimeout(timeoutHandle);
        return result;
    })
}


let SharedSentryInstance;

const getSentry = async () => {
  while (!SharedSentryInstance) {
    await delay(1000)
  }
  return SharedSentryInstance
}

export const initializeSentry = async () => {
  try {
    if (isProduction && !SharedSentryInstance) {
      const Sentry = await import('@sentry/browser')
      Sentry.init({
        dsn: "https://5b76037f5e044d60a955793ebc07c57d@o1371970.ingest.sentry.io/6676605",
        environment: "production",
        tracesSampleRate: 1.0,
      });
      SharedSentryInstance = Sentry
    }
  } catch (err) {
    console.error(err)
  }
}

export const captureSentryException = async (error) => {
  const sentryInstance = await getSentry()
  sentryInstance.captureException(error)
}

export const DefaultAspectRatio = 0.5625

export const MinDesktopWidthPixels = 961
export const MinLargeDesktopWidthPixels = 1500


export const sanitizeEmailAddress = emailAddress => emailAddress.toLowerCase().replace(/\s+/g, '')

export const sanitizeAffiliateCode = affiliateCode => affiliateCode.toUpperCase().replace(/\s+/g, '')

export const sanitizeCheckoutCode = affiliateCode => affiliateCode.toUpperCase().replace(/[^0-9A-Za-z\-]/g, '')

export const sanitizeTrainerInviteCode = trainerInviteCode => trainerInviteCode.toUpperCase().replace(/\s+/g, '')

export const sanitizeWebsiteAddress = websiteAddress => websiteAddress.toLowerCase().replace(/\s+/g, '')

export const preloadImagePaths = imagePaths => {
  imagePaths.map(imageSrc => {
    const img = new Image();
    img.src = imageSrc
  })
}

export const onceEventListener = (el, event, fn, opts) => {
  var onceFn = function (e) {
    el.removeEventListener(event, onceFn);
    fn.apply(this, arguments);
  };
  el.addEventListener(event, onceFn, opts);
  return onceFn;
}

export const NeuroFitMonthlyProductId = "neurofit_membership_monthly"
export const NeuroFitQuarterlyProductId = "neurofit_membership_quarterly"
export const NeuroFitYearlyProductId = "neurofit_membership_yearly"
export const NeuroFitMonthlyProductIdNoTrial = "neurofit_membership_monthly_no_trial"
export const NeuroFitQuarterlyProductIdNoTrial = "neurofit_membership_quarterly_no_trial"
export const NeuroFitYearlyProductIdNoTrial = "neurofit_membership_yearly_no_trial"

export const NeuroFitMonthlyProductIdV2 = "neurofit_membership_monthly_v2"
export const NeuroFitQuarterlyProductIdV2 = "neurofit_membership_quarterly_v2"
export const NeuroFitYearlyProductIdV2 = "neurofit_membership_yearly_v2"
export const NeuroFitMonthlyProductIdNoTrialV2 = "neurofit_membership_monthly_no_trial_v2"
export const NeuroFitQuarterlyProductIdNoTrialV2 = "neurofit_membership_quarterly_notrial_v2"
export const NeuroFitYearlyProductIdNoTrialV2 = "neurofit_membership_yearly_no_trial_v2"

export const FreeTrialInAppPurchaseProducts = [
  {
    id: NeuroFitMonthlyProductId,
    title: "Monthly",
    description: "Monthly NeuroFit Membership",
    price: "$19.99",
    priceMicros: 19990000,
  },
  {
    id: NeuroFitQuarterlyProductId,
    title: "3 Months",
    description: "Quarterly NeuroFit Membership",
    price: "$35.99",
    priceMicros: 35990000,
  },
  {
    id: NeuroFitYearlyProductId,
    title: "Yearly",
    description: "Yearly NeuroFit Membership",
    price: "$124.99",
    priceMicros: 124990000,
  }
]

export const FreeTrialInAppPurchasePremiumProducts = [
  {
    id: NeuroFitMonthlyProductIdV2,
    title: "Monthly",
    description: "Monthly NeuroFit Membership",
    price: "$44.99",
    priceMicros: 44990000,
  },
  {
    id: NeuroFitQuarterlyProductIdV2,
    title: "3 Months",
    description: "Quarterly NeuroFit Membership",
    price: "$99.99",
    priceMicros: 99990000,
  },
  {
    id: NeuroFitYearlyProductIdV2,
    title: "Yearly",
    description: "Yearly NeuroFit Membership",
    price: "$299.99",
    priceMicros: 299990000,
  }
]

export const NoFreeTrialInAppPurchaseProducts = [
  {
    id: NeuroFitMonthlyProductIdNoTrial,
    title: "Monthly",
    description: "Monthly NeuroFit Membership (No Free Trial)",
    price: "$19.99",
    priceMicros: 19990000,
  },
  {
    id: NeuroFitQuarterlyProductIdNoTrial,
    title: "Quarterly",
    description: "Quarterly NeuroFit Membership (No Free Trial)",
    price: "$35.99",
    priceMicros: 35990000,
  },
  {
    id: NeuroFitYearlyProductIdNoTrial,
    title: "Yearly",
    description: "Yearly NeuroFit Membership (No Free Trial)",
    price: "$124.99",
    priceMicros: 124990000,
  }
]

export const NoFreeTrialInAppPurchasePremiumProducts = [
  {
    id: NeuroFitMonthlyProductIdNoTrialV2,
    title: "Monthly",
    description: "Monthly NeuroFit Membership (No Free Trial)",
    price: "$44.99",
    priceMicros: 44990000,
  },
  {
    id: NeuroFitQuarterlyProductIdNoTrialV2,
    title: "Quarterly",
    description: "Quarterly NeuroFit Membership (No Free Trial)",
    price: "$99.99",
    priceMicros: 99990000,
  },
  {
    id: NeuroFitYearlyProductIdNoTrialV2,
    title: "Yearly",
    description: "Yearly NeuroFit Membership (No Free Trial)",
    price: "$299.99",
    priceMicros: 299990000,
  }
]
