import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import common from './reducers/web';
import quizPage from './reducers/quizPage'
import certificationVideoPage from './reducers/certificationVideoPage'
import trainingRegistrationPage from './reducers/trainingRegistrationPage'

export default combineReducers({
  common,
  quizPage,
  certificationVideoPage,
  trainingRegistrationPage,
  router: routerReducer
});
