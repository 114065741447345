import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import TrainerCertificationVideoDetails from './common/TrainerCertificationVideoDetails'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK
} from '../constants/gaEventLabels'

import {
  REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS,
} from '../constants/requestTypes'

import {
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_USE_VSL,
} from '../constants/experimentFlags'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionCertificationUrl,
  ProductionCourseUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  CertificationCheckoutLink,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
  identifyCertificationAnalyticsWebUser,
  delay,
} from '../utils'

import Helmet from './common/Helmet'

import agent from '../agent'

class CertificationPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      logoOutlineColor: "#EDEEF2",
      topPageScrollProgress: 0,
      trainingSignUpResultState: REQUEST_UNSTARTED,
    }
  }
  
  async signUpForTraining(body) {
    const {experimentFlags} = this.props

    this.setState({...this.state, trainingSignUpResultState: REQUEST_FETCHING})
    try {
      const trainingRequestResult = await agent.NeuroFitBackend.submitCertificationVideoRegistration(body)
      this.setState({...this.state, trainingSignUpResultState: REQUEST_SUCCESS})

      // Pre-warm the Browser Cache with unique code data, then redirect to either VSL or Sales page.
      const unique_code = trainingRequestResult.unique_code

      await identifyCertificationAnalyticsWebUser({uuid: unique_code})
      await agent.NeuroFitBackend.retrieveCertificationUniqueCodeDetails({unique_code})
      await delay(500)
      const redirectBasePath = "/certification"
      window.location = `${redirectBasePath}?c=${unique_code}`

    } catch (err) {
      console.log(err)
      this.setState({...this.state, trainingSignUpResultState: REQUEST_ERROR})
    }
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      i18n,
      isIOSDevice,
      isAndroidDevice,
      isNasmPage,
      requestResults,
      onTriggerRequest,
      onUpdateRequestState,
      experimentFlags
    } = this.props

    const {headerBorderColor, headerBackgroundColor, headerBackgroundBlur, logoOutlineColor, textShadow, topPageScrollProgress, trainingSignUpResultState} = this.state
    
    const appDownloadUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)

    return (
      <div style={{height: innerHeight}}>
        <CSSTransition
          in
          timeout={400}
          classNames={"fade-in-400"}
          onExited={() => {}}
        >
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#FFFFFF"}}>
            <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
            <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: "min(20px, 4%)", paddingBottom: 12, backgroundColor: headerBackgroundColor, zIndex: 1000, opacity: topPageScrollProgress, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
              <NeuroFitNamedLogoSvg
                logoSize={"min(5.75vw, 24px)"}
                color={"#000000"}
                dropShadowColor={logoOutlineColor}
              />
            </div>
            <TrainerCertificationVideoDetails
              innerHeight={innerHeight}
              safeAreaInsets={safeAreaInsets}
              isNative={isNative}
              onSignUpForTraining={async (body) => {
                await this.signUpForTraining(body)
              }}
              signUpForTrainingRequestResult={{state: trainingSignUpResultState}}
              onContainerScroll={updatedState => this.setState({...updatedState})}
              experimentFlags={experimentFlags}
            />
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default CertificationPage;