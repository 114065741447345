import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionBookDownloadUrl,
} from '../utils'

import Helmet from './common/Helmet'

const NeuroFitBookPdfDownloadUrl = "https://neurofit.app/downloads/9lWPpvs9UjtT54efP2FoLWClzkx2Ap/neurofit-book.pdf"

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class BookDownloadPage extends React.Component {
  constructor(props){
     super(props)
     this.state = {
        learnMoreRef: React.createRef(),
        headerColor: "transparent",
        dropShadowColor: "transparent",
        show: true,
        showIframe: false,
        bookImageLoaded: false,
        signatureLoaded: false
     }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      i18n,
      isIOSDevice,
      isAndroidDevice,
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showIframe, bookImageLoaded, signatureLoaded} = this.state

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"Download the NEUROFIT Book"}
          description={"Click the link below to download your copy of the NEUROFIT Book."}
          href={ProductionBookDownloadUrl}
        />
        <CSSTransition
          in={show}
          timeout={400}
          classNames={"fade-in-400"}
          onExited={() => {}}
        >
          <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#FFFFFF"}}>
            <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
            <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingLeft: "min(40px, 7.5%)", paddingRight: 12, paddingBottom: 12, backgroundColor: "rgba(255, 255, 255, 0.7)", backdropFilter: "blur(7px)", WebkitBackdropFilter: "blur(7px)", zIndex: 1000}}>
              <NeuroFitNamedLogoSvg
                logoSize={"min(5.75vw, 24px)"}
                color={"#000000"}
                onClick={() => window.location = "/"}
              />
            </div>
            <div 
              id={"landing-page-container"}
              className={"disable-scrollbars"}
              ref={elem => this.scrollingContainerRef = elem}
              style={containerStyle}
            >
              <div style={{textAlign: "left", width: "100vw",}}>
                <div style={{color: "black", width: "100%", display: "flex", minHeight: innerHeight, justifyContent: "center", alignItems: "top", marginTop: "calc(60px + 5vh)"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div style={{display: "inline-block", fontSize: "min(8vw, 36px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                      {`Your Book Copy`}
                    </div>
                    <div style={{width: "100%", padding: "3vh 0px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <img
                        src="/images/neurofit-book-cover.webp"
                        fetchpriority="high"
                        style={{width: "min(170px, 40vw)", height: "calc(613 / 354 * min(170px, 40vw))", opacity: bookImageLoaded ? 1 : 0, transition: "opacity 150ms linear"}}
                        onLoad={() => this.setState({...this.state, bookImageLoaded: true, signatureLoaded})}
                        alt="NeuroFit: An interactive guide to nervous system regulation. How to reduce stress, feel better in your body, and perform at your best."
                      />
                    </div>
                    <div style={{fontSize: "min(6vw, 26px)", fontStyle: "italic", textAlign: "left", fontFamily: "Futura Light", marginRight: "0.3em", marginTop: `calc(2vh)`}}>
                      {`Thanks for joining the training! Your book is available below.`}
                    </div>
                    <div style={{fontFamily: "Futura Light", fontStyle: "italic", fontSize: "min(6vw, 26px)", marginTop: "1.5vh"}}>
                      {"With Love,"}
                    </div>
                    <div style={{marginTop: "1.5vh"}}>
                      <img
                        src="/images/loren-signature.webp"
                        alt="Loren Hogue Signature"
                        style={{width: 150, height: 81.5}} />
                    </div>
                    <div style={{fontFamily: "Futura", fontSize: "min(5.5vw, 22px)"}}>
                      {"Loren Hogue, CEO, NEUROFIT"}
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <a
                        href={NeuroFitBookPdfDownloadUrl}
                        target={"_blank"}
                        download={"neurofit-book.pdf"}
                        style={{display: "flex", cursor: "pointer", textDecoration: "none", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, marginTop: "4vh", textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                      >
                        <span>
                          {"DOWNLOAD THE BOOK"}
                        </span>
                      </a>
                    </div>
                    <div style={{height: "6vh"}}>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw"}}>
                <div style={{marginTop: 15, padding: 15, fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                  <div style={{display: "inline-block"}}>
                    <span style={{color: "#c1c4d2"}}>
                      {"© NEUROFIT 2023"}
                    </span>
                    <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                      {"•"}
                    </span>
                    <span style={{color: "#c1c4d2"}}>
                      {"ALL RIGHTS RESERVED"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default BookDownloadPage;