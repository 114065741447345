import React, {Suspense} from 'react'

import PendingResultsPanel from './PendingResultsPanel'

import {
  NeuroFitNamedLogoSvg,
  NeuroFitLogoSvg,
} from '../../constants/svgs'


import loadable from '@loadable/component'
const ChartistGraph = loadable(() => import('react-chartist'))
// const chartistPluginAxisTitle = loadable(() => import('chartist-plugin-axistitle'))
// import ChartistGraph from 'react-chartist';
// import chartistPluginAxisTitle from 'chartist-plugin-axistitle'

class LineGraph extends React.Component {

  constructor(props) {
    super(props)
  }

  preload() {
    ChartistGraph.preload()
  }

  render() {
    const {
      dataIsAvailable,
      title,
      data,
      labelInterpolationFnc,
      highYValue,
      pendingTitle,
      pendingText,
      xAxisTitle,
      hideLogo,
      marginTop
    } = this.props

    return (
      <div>
        {dataIsAvailable ? (
          <div>
            <div style={{backgroundColor: "#FFFFFF", padding: "min(4vw, 20px)", marginTop: marginTop || "max(3vw, 16px)", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
              <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div style={{display: "inline-block", fontFamily: "Futura", fontWeight: "normal", fontSize: "min(5vw, 22px)", lineHeight: "min(5vw, 22px)"}}>
                  {title}
                </div>
              </div>
              <Suspense fallback={
                <div className={"article-loading-background-animation"} style={{height: 190, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <NeuroFitLogoSvg
                    size={"min(8vw, 40px)"}
                    color={"#CFD2DD"}
                    lineColor={"#EDEEF2"}
                  />
                </div>
              }>
                <div>
                  <ChartistGraph
                    data={data}
                    options={{
                      height: '190px',
                      fullWidth: true,
                      chartPadding: {
                        top: 20, 
                        bottom: !!(xAxisTitle) ? 10 : 2,
                        left: 0,
                        right: 20
                      },
                      labelDirection: "explode",
                      axisY: {
                        labelInterpolationFnc,
                        low: 0,
                        high: highYValue,
                        referenceValue: 20,
                        onlyInteger: true,
                        offset: 50
                      },
                      axisX: {
                        // showGrid: false,
                        name: xAxisTitle,
                        showLabel: !!(xAxisTitle),
                        offset: (!!(xAxisTitle)) ? 10 : 0,
                      }
                    }}
                    type="Line"
                  />
                  {!hideLogo && (
                    <div style={{display: "flex", justifyContent: "right", alignItems: "center", marginTop: -40, paddingRight:30}}>
                      <NeuroFitNamedLogoSvg
                        logoSize={"min(4vw, 18px)"}
                        color={"#D5D6D9"}
                      />
                    </div>
                  )}
                </div>
              </Suspense>
              {!!(xAxisTitle) && (
                <div style={{textAlign: "center", fontFamily: "Futura", fontWeight: "normal", fontSize: "min(4vw, 18px)"}}>
                  {xAxisTitle}
                </div>
              )}
            </div>
          </div>
        ) : (
          <PendingResultsPanel
            title={pendingTitle}
            color={"#999"}
            pendingText={pendingText}
          />
        )}
      </div>
    )
  }
}

export { ChartistGraph }; 
export default LineGraph;