import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  MinDesktopWidthPixels,
  subdomainUrls,
} from '../../utils'

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './LoadingLogo'
import AutoPlayInlineVideoContainer from './AutoPlayInlineVideoContainer'
import PieChartInsightSection from './PieChartInsightSection'
import AppReviewPanel from './AppReviewPanel'
import LineGraph from './LineGraph'
import MembershipFeatureDescription from './MembershipFeatureDescription'
import SendRequestButton from './SendRequestButton'

import agent from '../../agent'

import {
  NeuroFitCutoutSvg,
} from '../../constants/svgs'

import {
  GA_LABEL_CLICKED_REGISTER_FOR_CERTIFICATION_TRAINING,
  GA_LABEL_CERTIFICATION_OPT_IN_PAGE_SCROLLED_DOWN,
} from '../../constants/gaEventLabels'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
} from '../../constants/requestStates'

import {
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_RED_BUTTON,
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_DISPLAY_LIMITED_TIME_HERO_CTA_TEXT,
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_SHOW_PRESS_AT_BOTTOM_ABOVE_FOLD,
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_HIDE_ALL_SECTIONS_BELOW_FOLD,
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_HERO_CTA_SHOW_PERCENT_OFF,
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_HERO_PRESS_SHOW_WELL_AND_GOOD_LAUREL,
} from '../../constants/experimentFlags'

import TopTrainerTestimonials from './TopTrainerTestimonials'


const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class TrainerCertificationVideoDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSettingsModal: false,
      hasScrolled: false,
      signUpRef: React.createRef(),
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      firstName: "",
      emailAddress: ""
    }
  }

  handleOnClickLearnMore = (event) => {
    //.current is verification that your element has rendered
    if (this.learnMoreRef.current){
        this.learnMoreRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  handleOnScrollToCheckout = () => {
    if (this.checkoutRef.current) {
        this.checkoutRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const {hasScrolled} = this.state

    if (!hasScrolled) {
      recordFunnelEventGA(GA_LABEL_CERTIFICATION_OPT_IN_PAGE_SCROLLED_DOWN)
    }

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`

      const updatedState = {headerBackgroundColor, hasScrolled: true, heroOverlayColor, dropShadowColor, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", hasScrolled: true, heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)

    await initializePixelIfAvailable()
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      isNasmPage,
      signUpForTrainingRequestResult,
      onSignUpForTraining,
      experimentFlags,
    } = this.props


    const {show, firstName, emailAddress, hasScrolled, learnMoreRef, showSettingsModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")
    const coachTextShadow = range(4).map(() => (`0px 0px 5px rgba(0, 0, 0, 0.16)`)).join(",")

    const signUpInProgress = signUpForTrainingRequestResult.state === REQUEST_FETCHING

    const registerButtonDisabled = (!firstName) || (!emailAddress) || signUpInProgress

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const experimentUseRedButton = experimentFlags[EXPERIMENT_FLAGS_WEB_CERT_OFFER_RED_BUTTON]
    const experimentDisplayLimitedTimeHeroText = experimentFlags[EXPERIMENT_FLAGS_WEB_CERT_OFFER_DISPLAY_LIMITED_TIME_HERO_CTA_TEXT]
    const experimentShowPressAtBottomAboveFold = experimentFlags[EXPERIMENT_FLAGS_WEB_CERT_OFFER_SHOW_PRESS_AT_BOTTOM_ABOVE_FOLD]
    const experimentHideAllSectionsBelowFold = experimentFlags[EXPERIMENT_FLAGS_WEB_CERT_OFFER_HIDE_ALL_SECTIONS_BELOW_FOLD]
    const experimentCtaShowPercentOff = experimentFlags[EXPERIMENT_FLAGS_WEB_CERT_OFFER_HERO_CTA_SHOW_PERCENT_OFF]
    const experimentPressShowWellAndGoodLaurel = experimentFlags[EXPERIMENT_FLAGS_WEB_CERT_OFFER_HERO_PRESS_SHOW_WELL_AND_GOOD_LAUREL]

    const heroText = `Become An Expert Nervous System Fitness™ Trainer`
    const secondPageHeroText = `Get Superior Client Results With Nervous System Fitness™`

    const discountAmountString = experimentCtaShowPercentOff ? `$200 (29%)` : `$200`

    const heroCtaText = (experimentDisplayLimitedTimeHeroText ? `Take ${discountAmountString} off the NEUROFIT Certification: register to unlock this limited-time offer now.` : `Take ${discountAmountString} off the NEUROFIT guided certification program: register to unlock now.`)

    return (
      <div 
        id={"certification-page-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={containerStyle}
      >
        <div style={{textAlign: "left", width: "100vw", minHeight: innerHeight, backgroundColor: "#EDEEF2"}}>
          <div style={{display: "flex", minHeight: innerHeight, height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}} ref={elem => this.signUpRef = elem}>
            <div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: 60, pointerEvents: "none"}}>
                <div style={{width: "85%", maxWidth: 600}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textShadow, textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                    {heroText}
                  </div>
                </div>
              </div>
            </div>
            {!experimentShowPressAtBottomAboveFold && (
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", pointerEvents: "none", margin: "3vh 0px"}}>
                <div style={{width: "min(80vw, 400px)", height: experimentPressShowWellAndGoodLaurel ? undefined : "calc(min(80vw, 400px) * 0.1598)", textAlign: "center", pointerEvents: "none", opacity: 0.5}}>
                  {experimentPressShowWellAndGoodLaurel ? (
                    <img src={"/images/well-and-good-laurel-black.webp"} style={{width: "85%"}} alt="NeuroFit has been featured in Well and Good." />
                  ) : (
                    <img src={"/images/cert-offer-press-banner.webp"} style={{width: "100%"}} alt="NeuroFit has been featured in SHAPE, Well and Good, and Body and Soul." />
                  )}
                </div>
              </div>
            )}
            <div style={{color: "black", width: "100%", marginTop: !experimentShowPressAtBottomAboveFold ? 0 : "2vh", display: "flex", justifyContent: "center", alignItems: "top", pointerEvents: "none"}}>
              <div style={{width: "85%", maxWidth: 600}}>
                <div style={{display: "inline-block", fontSize: "min(6vw, 26px)", textShadow, textAlign: "left", fontFamily: "Futura", marginRight: "0.3em"}}>
                  <span>{heroCtaText}</span>
                </div>
              </div>
            </div>
            <div>
              <div style={{color: "black", width: "100%", display: "flex", marginTop: `2vh`, justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 600}}>
                  <div>
                    <input
                      type="text"
                      value={firstName}
                      disabled={signUpInProgress}
                      autoCapitalize="true"
                      autoCorrect="false"
                      autoComplete="given-name"
                      spellCheck={false}
                      onChange={(e) => {
                        this.setState({firstName: e.target.value})
                      }}
                      onPaste={(e) => {
                        this.setState({firstName: e.target.value})
                      }}
                      onKeyDown={(e) => {
                        if(e.key === 'Enter' && (!registerButtonDisabled)) {
                          recordPixelEventIfAvailable("Lead")
                          recordFunnelEventGA(GA_LABEL_CLICKED_REGISTER_FOR_CERTIFICATION_TRAINING)
                          onSignUpForTraining({first_name: firstName, email_address: emailAddress})
                        }
                      }}
                      placeholder={"First Name"}
                      className={"application-input"}
                    />
                  </div>
                  <div style={{margin: "min(2vh, 10px) 0px"}}>
                    <input
                      type="text"
                      value={emailAddress}
                      disabled={signUpInProgress}
                      autoCapitalize="none"
                      autoCorrect="false"
                      autoComplete="email"
                      spellCheck={false}
                      onChange={(e) => {
                        this.setState({emailAddress: e.target.value.toLowerCase()})
                      }}
                      onPaste={(e) => {
                        this.setState({emailAddress: e.target.value.toLowerCase()})
                      }}
                      onKeyDown={(e) => {
                        if(e.key === 'Enter' && (!registerButtonDisabled)) {
                          recordPixelEventIfAvailable("Lead")
                          recordFunnelEventGA(GA_LABEL_CLICKED_REGISTER_FOR_CERTIFICATION_TRAINING)
                          onSignUpForTraining({first_name: firstName, email_address: emailAddress})
                        }
                      }}
                      placeholder={"Email Address"}
                      className={"application-input"}
                    />
                  </div>
                  <SendRequestButton
                    defaultContent={"UNLOCK $200 OFF"}
                    backgroundColor={experimentUseRedButton ? "#FF0000" : "#000000"}
                    loadingText={"REGISTERING..."}
                    successText={"OFFER UNLOCKED"}
                    requestState={signUpForTrainingRequestResult.state}
                    isDisabled={registerButtonDisabled}
                    onClick={() => {
                      recordPixelEventIfAvailable("Lead")
                      recordFunnelEventGA(GA_LABEL_CLICKED_REGISTER_FOR_CERTIFICATION_TRAINING)
                      onSignUpForTraining({first_name: firstName, email_address: emailAddress})
                    }}
                  />
                  <div style={{fontSize: "min(4vw, 18px)", color: "#a5a8b0", marginTop: "min(2vh, 10px)"}}>
                    <span>{(experimentDisplayLimitedTimeHeroText ? "" : "Limited-time offer. ") + "By submitting this form, you agree to our "}</span>
                    <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#terms`} target="_blank" rel="noopener noreferrer nofollow">{"Terms"}</a>
                    <span style={{marginRight: "0.2em"}}>{" and "}</span>
                    <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#privacy`} target="_blank" rel="noopener noreferrer nofollow">{"Privacy Policy"}</a>
                    <span>{". Thank you!"}</span>
                  </div>
                </div>
              </div>
            </div>
            {experimentShowPressAtBottomAboveFold && (
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", pointerEvents: "none", margin: "3vh 0px"}}>
                <div style={{width: "min(80vw, 400px)", height: experimentPressShowWellAndGoodLaurel ? undefined : "calc(min(80vw, 400px) * 0.1598)", textAlign: "center", pointerEvents: "none", opacity: 0.5}}>
                  {experimentPressShowWellAndGoodLaurel ? (
                    <img src={"/images/well-and-good-laurel-black.webp"} style={{width: "85%"}} alt="NeuroFit has been featured in Well and Good." />
                  ) : (
                    <img src={"/images/cert-offer-press-banner.webp"} style={{width: "100%"}} alt="NeuroFit has been featured in SHAPE, Well and Good, and Body and Soul." />
                  )}
                </div>
              </div>
            )}
            <div style={{height: "3vh"}}>
            </div>
          </div>
        </div>
        {!experimentHideAllSectionsBelowFold && (
          <div style={{padding: "calc(60px + 5vh) 0px", textAlign: "left", minHeight: `calc(${innerHeight - 120}px - 10vh)`, width: "100vw", borderTop: "2px #000000 solid", backgroundColor: "#FFFFFF"}} ref={elem => this.learnMoreRef = elem}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 600}}>
              <div>
                <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                  {secondPageHeroText}
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "4vh"}}>
                  <img
                    src={isMobile ? "/images/certification-hero-dash-mobile.webp" : "/images/certification-hero-dash.webp"}
                    fetchpriority="high"
                    onLoad={() => {
                      this.setState({isHeroImageLoaded: true})
                      initializePixelIfAvailable()
                    }}
                    style={{maxWidth: 700, width: "100%"}}
                    alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth."
                  />
                </div>
                <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  {`Master nervous system regulation, somatic release exercises, and trauma-informed witnessing, and become a Certified Trainer with our:`}
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura",fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Nervous System Certification Course"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Industry-Leading Nervous System Tech + Toolkit"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Done-For-You NeuroFit Business Suite, and"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"3 months of ongoing support, Live Coaching and Trainer Community"}
                  </div>
                </div>
                <div style={{marginTop: "2vh", fontSize: "min(6vw, 25px)", textAlign: "left", fontFamily: "Futura Light"}}>
                  <span>{`Join a community of`}</span>
                  <span style={{fontFamily: "Futura"}}>{` hundreds of doctors, health coaches, healers, therapists and more `}</span>
                  <span>{`accelerating client outcomes and driving business growth with NEUROFIT.`}</span>
                </div>
              </div>
            </div>
          </div>
          <div style={{textAlign: "left", width: "100vw", padding: "6vh 0vh", backgroundColor: "transparent"}}>
            {!experimentPressShowWellAndGoodLaurel && (
              <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-around"}}>
                <img src="/images/well-and-good-laurel-black.webp" style={{width: "73%", maxWidth: 350}} alt="NeuroFit eased my stress in ways meditation never has -Well and Good" />
              </div>
            )}
            <div style={{display: "flex", marginTop: "3vh", width: "100%", alignItems: "center", justifyContent: "space-around"}}>
              <img src="/images/body-and-soul-laurel-black.webp" style={{width: "85%", maxWidth: 400}} alt="I finally found something to calm my stressed out nervous system -Body and Soul" />
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 600}}>
              <div style={{display: "flex", justifyContent: "center", marginTop: "2vh"}}>
                <button
                  style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#ffffff", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                  onClick={() => {
                    this.signUpRef.scrollIntoView({behavior: "smooth"})
                  }}
                >
                  {"UNLOCK $200 OFF"}
                </button>
              </div>
            </div>
          </div>
          </div>
        )}
        {!experimentHideAllSectionsBelowFold && (
          <div style={{textAlign: "left", width: "100vw", paddingTop: `calc(60px + 5vh)`, backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                {"Join Hundreds Of Embodied Health Experts"}
              </div>
              <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {"From doctors to therapists, chiropractors to military nurses, the NEUROFIT method + tech empowers a diverse range of health professionals."}
              </div>
            </div>
          </div>
          <TopTrainerTestimonials innerWidth={innerWidth} />
          <div style={{textAlign: "left", width: "100vw", paddingTop: "calc(60px + 5vh)", paddingBottom: safeAreaInsets.bottom}}>
            <div style={{padding: 15, paddingBottom: 15 + safeAreaInsets.bottom, backgroundColor: "transparent", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
              <div style={{display: "inline-block"}}>
                <span style={{color: "#c1c4d2"}}>
                  {"© NEUROFIT 2023"}
                </span>
                <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                  {"•"}
                </span>
                <span style={{color: "#c1c4d2"}}>
                  {"ALL RIGHTS RESERVED"}
                </span>
              </div>
            </div>
          </div>
          </div>
        )}
      </div>
    )
  }
}

export default TrainerCertificationVideoDetails