import React from 'react';

import {
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  NeuroFitUniversalAppStoreLink,
  recordLinkClickAndRedirect,
  recordPixelEventIfAvailable,
} from '../../utils'

import LoadingLogo from './LoadingLogo'

import {
  GA_LABEL_CLICKED_APP_DOWNLOAD_LINK,
} from '../../constants/gaEventLabels'

import {
  AppleSvg,
  AndroidSvg,
} from '../../constants/svgs'

const DownloadButtonsSection = ({
  isIOSDevice,
  isAndroidDevice,
  useWhiteButtons,
  onShowQrCodeModal,
  onDownloadButtonClick
}) => (
  <div>
    {(isIOSDevice || isAndroidDevice) ? (
      <div style={{display: "flex", justifyContent: "center"}}>
        <button
          style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "3vh", color: useWhiteButtons ? "#000000" : "#ffffff", backgroundColor: useWhiteButtons ? "#FFFFFF" : "#000000", padding: "18px 15px", border: useWhiteButtons ? "2px #000000 solid" : "none"}}
          onClick={() => {
            if (!!onDownloadButtonClick) {
              onDownloadButtonClick()
            }
            const redirectUrl = isIOSDevice ? NeuroFitAppleAppStoreLink : (isAndroidDevice ? NeuroFitGooglePlayStoreLink : NeuroFitUniversalAppStoreLink)
            recordPixelEventIfAvailable("StartTrial")
            recordLinkClickAndRedirect(GA_LABEL_CLICKED_APP_DOWNLOAD_LINK, redirectUrl)
          }}
        >
          {isIOSDevice ? (
            <AppleSvg color={useWhiteButtons ? "#000000" : "#FFFFFF"} />
          ) : (isAndroidDevice ? (
            <AndroidSvg color={useWhiteButtons ? "#000000" : "#FFFFFF"} />
          ) : null)}
          <span>
            {`${isIOSDevice ? "DOWNLOAD THE APP" : (isAndroidDevice ? "DOWNLOAD THE APP" : "DOWNLOAD THE APP")}`}
          </span>
        </button>
      </div>
    ) : (
      <div>
        <div style={{display: "flex", justifyContent: "center"}}>
          <button
            style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "min(32px, 3vh)", color: useWhiteButtons ? "#000000" : "#ffffff", backgroundColor: useWhiteButtons ? "#FFFFFF" : "#000000", padding: "18px 15px", border: useWhiteButtons ? "2px #000000 solid" : "none"}}
            onClick={() => {
              recordPixelEventIfAvailable("StartTrial")
              onShowQrCodeModal()
            }}
          >
            <LoadingLogo
              show={true}
              hideAnimation={true}
              responsiveSize={"min(6vw, 25px)"}
              marginTop={"0px"}
              padding={"0px"}
              marginRight={"0.4em"}
              color={useWhiteButtons ? "#000000" : "#FFFFFF"}
              lineColor={useWhiteButtons ? "#FFFFFF" : "#000000"}
              fontFamily={"Futura"}
              text={"DOWNLOAD THE APP"}
              showProgressionBar={false}
            />
          </button>
        </div>
      </div>
    )}
  </div>
)

export default DownloadButtonsSection