import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventResultGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  getAffiliateIdFromQueryString,
  MinDesktopWidthPixels,
  isIOSDevice,
  NeuroFitCourseCheckoutPage,
  NeuroFitCertificationCheckoutPage,
  recordLinkClickAndRedirect,
  CertificationThreePaymentCheckoutLink,
  getNextEnrollmentCloseUnixTimestamp,
  getDiscountCodeExpirationMetadata
} from '../../utils'

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './LoadingLogo'
import AutoPlayInlineVideoContainer from './AutoPlayInlineVideoContainer'
import LineGraph from './LineGraph'
import UniversitySourcesSection from './UniversitySourcesSection'
import FaqSection from './FaqSection'
import CertificationPageProductTile from './CertificationPageProductTile'
import MembershipFeatureDescription from './MembershipFeatureDescription'

import Countdown from 'react-countdown';

import {
  REQUEST_SUCCESS,
} from '../../constants/requestStates'

import {
  GA_LABEL_CLICKED_CERTIFICATION_CHECKOUT_BUTTON,
  GA_LABEL_CLICKED_REDIRECT_TO_COURSE_CHECKOUT_PAGE,
  GA_LABEL_CLICKED_REDIRECT_TO_CERTIFICATION_CHECKOUT_PAGE,
} from '../../constants/gaEventLabels'

import {
  ExternalLinkSvg,
  CopySvg,
} from '../../constants/svgs'

import loadable from '@loadable/component'

const StripeCheckoutForm = loadable(() => import('./StripeCheckoutForm'))
const TrainerTestimonials = loadable(() => import('./TrainerTestimonials'))
const TrainerFAQs = loadable(() => import('./TrainerFAQs'))



const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


const CourseOutlineBulletPoint = ({text}) => (
  <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0px 2vh"}}>
    <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(6vw, 26px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
      {"•"}
    </div>
    <div style={{fontFamily: "Futura Light", fontSize: "min(5vw, 20px)", lineHeight: "min(6vw, 26px)", textAlign: "left"}}>
      {text}
    </div>
  </div>
)

const CourseOutlineBulletPoints = ({textList}) => (
  <div>
    {textList.map(t => (
      <CourseOutlineBulletPoint key={t} text={t} />
    ))}
  </div>
);

const CourseOutlineItems = [
  {
    key: "module_1",
    question: "Module 1: Setting Yourself Up for Success",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "The 3-Part Transformation Framework that creates accelerated results",
          "The #1 avoidable mistake that most nervous system experts make",
          "The 4 Truths that will uplevel your standards and dramatically improve your results"
        ]}
      />
    )
  },
  {
    key: "module_2",
    question: "Module 2: Introduction to the NeuroFit Method",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "The fundamentals of the NeuroFit Method (and why it's so effective)",
          "How to practically apply Polyvagal Theory with clients",
          "4 Key Factors that secretly influence the nervous system",
          "Using Bottom-Up Processing to your advantage",
          "The 6 nervous system states, and how to quickly identify them",
          "How to measure nervous system balance in less than 2 minutes"
        ]}
      />
    )
  },
  {
    key: "module_3",
    question: "Module 3: Taking a Client from Dysregulated to Balanced in Minutes (A Step-by-Step Process)",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "Understanding the 3 main energy types to get faster client breakthroughs",
          "The 3 best exercises when working with anxious clients",
          "The 3 best exercises when working with overwhelmed clients",
          "The 3 best exercises when working with clients in shutdown"
        ]}
      />
    )
  },
  {
    key: "module_4",
    question: "Module 4: Advanced Techniques for Personal & Client Breakthroughs",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "What to do (on a nervous system level) when a client experiences resistance to change",
          "Facilitating client safety during sessions via the social nervous system",
          "Advanced somatic and balancing techniques for facilitating powerful client breakthroughs",
          "The 3 types of accountability habits that are proven to accelerate client results"
        ]}
      />
    )
  },
  {
    key: "module_5",
    question: "Module 5: Facilitating Powerful Transformations with Nervous System Fitness™",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "A step-by-step process for hosting transformational workshops that your clients will rave about",
          "A walkthrough guide for facilitating life-changing 1:1 client sessions"
        ]}
      />
    )
  },
  {
    key: "module_6",
    question: "Module 6: A Fitness Trainer Approach to the NeuroFit Method",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "How to incorporate the NeuroFit Method during client sessions, to optimize productivity and outcomes",
          "Done-for-you client intake form, that helps your clients understand the ways their nervous system is dysregulated",
          "Done-for-you dynamic warm-up sequence you can implement with clients immediately"
        ]}
      />
    )
  },
  {
    key: "module_7",
    question: "Module 7: Embodied Marketing & Distribution",
    answer: (
      <CourseOutlineBulletPoints
        textList={[
          "How to grow your client base in 2023 by leveraging widespread burnout and chronic stress",
          "Developing your personalized Blue Ocean Strategy to integrate nervous system with your practice",
          "How to use the NeuroFit Method to bring on more clients",
          "Simple social media templates and strategies that you can implement immediately"
        ]}
      />
    )
  }
]

let defaultCourseItemsShowDetail = {}
CourseOutlineItems.map(key => defaultCourseItemsShowDetail[key] = false)

class TrainerCertificationDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isIOSDevice: isIOSDevice(),
      showCourseDetail: defaultCourseItemsShowDetail,
      showSettingsModal: false,
      learnMoreRef: React.createRef(),
      superiorResultsRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      bottomPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      coachBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      codeIsCopied: false
    }
  }

  handleOnClickLearnMore = (event) => {
    //.current is verification that your element has rendered
    if (this.learnMoreRef.current){
        this.learnMoreRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  handleOnScrollToCheckout = () => {
    if (this.checkoutRef.current) {
        this.checkoutRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  adjustBgPositionById = (id, scrollTop, innerHeight, xvalue="center", bgImage=undefined) => {
    let target = document.getElementById(id)

    // let factor = this.props.isIOSDevice ? 0.0 : 0.0 //0.2
    // let yvalue = (target.offsetTop - scrollTop) * factor
    // let yvalueSign = `${(yvalue > 0 ? "-" : "")}`

    if (!!(bgImage) && !!(target)) {
      target.style.backgroundImage = bgImage
    }

    // target.style.backgroundPosition = `${xvalue} ${yvalue}px`
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const hasScrolled = true

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`

      const updatedState = {headerBackgroundColor, heroOverlayColor, hasScrolled, dropShadowColor, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", heroOverlayColor: "transparent", heroBackdropFilter: "none", coachBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, bottomPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      isNasmPage,
      onClickNativeVslLink,
      certificationCodeDetailsRequest
    } = this.props


    const {show, showCourseDetail, learnMoreRef, hasScrolled, showSettingsModal, scrollingContainerRef, superiorResultsRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, coachBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, bottomPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity, codeIsCopied} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")
    const coachTextShadow = range(4).map(() => (`0px 0px 5px rgba(0, 0, 0, 0.16)`)).join(",")

    const enrollmentCloseDate = getNextEnrollmentCloseUnixTimestamp()

    const discountCodeExpirationMetadata = (certificationCodeDetailsRequest.state === REQUEST_SUCCESS) && getDiscountCodeExpirationMetadata(certificationCodeDetailsRequest.result.expires_at_unix)

    return (
      <div 
        id={"certification-page-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={containerStyle}
      >
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, right: 0, backgroundColor: "transparent", pointerEvents: "none", transition: "all 300ms ease-out", transitionProperty: "background-color", zIndex: 2000}}>
        </div>
        <div style={{textAlign: "left", width: "100vw", height: innerHeight, pointerEvents: "none"}}>
          <CSSTransition
            in={!isHeroImageLoaded}
            timeout={400}
            classNames={"fade-in"}
            onExited={() => {}}
            unmountOnExit
          >
            <div className={"video-loading-background-animation"} style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", alignItems: "center", justifyContent: "center", zIndex: -2}}>
              <LoadingLogo
                show={true}
                hideTitle={true}
                hideAnimation={true}
                responsiveSize={"100px"}
                marginTop={0}
                marginRight={"0px"}
                color={"#CFD2DD"}
                lineColor={"#EDEEF2"}
                fontFamily={"Futura"}
                showProgressionBar={false}
              />
            </div>
          </CSSTransition>
          <div style={{position: "fixed", top: 0, width: innerWidth, backgroundColor: "#EDEEF2", height: "100%", display: "flex", justifyContent: "center", zIndex: -2, opacity: isHeroImageLoaded ? 1.0 : 0.0, transition: "opacity 250ms linear"}}>
            <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <img
                src={isMobile ? "/images/certification-hero-dash-mobile.webp" : "/images/certification-hero-dash.webp"}
                fetchpriority="high"
                onLoad={() => {
                  this.setState({isHeroImageLoaded: true})
                  initializePixelIfAvailable()
                }}
                style={{maxWidth: 700, width: "85%"}}
                alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth."
              />
            </div>
          </div>
          <div style={{position: "fixed", top: 0, width: innerWidth, height: "100%", display: "flex", justifyContent: "center", zIndex: (topPageScrollProgress > 0) ? -1 : 0}}>
            <div style={{display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: 60, pointerEvents: "none"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textShadow, textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", marginTop: `calc(3vh + ${safeAreaInsets.top}px)`, textTransform: "uppercase"}}>
                    {`BECOME AN EXPERT NERVOUS SYSTEM FITNESS™ TRAINER`}
                  </div>
                </div>
              </div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginBottom: "5vh", pointerEvents: "none"}}>
                <div style={{width: "85%", maxWidth: 700, pointerEvents: "none"}}>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                      style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "3vh", color: "#FFFFFF", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                      onClick={() => {
                        recordPixelEventIfAvailable("Lead")
                        recordFunnelEventResultGA(GA_LABEL_CLICKED_CERTIFICATION_CHECKOUT_BUTTON, {location: "hero"})
                        this.superiorResultsRef.scrollIntoView({behavior: "smooth"})
                      }}
                    >
                      <LoadingLogo
                        show={true}
                        hideAnimation={true}
                        hideLogo
                        responsiveSize={"min(6vw, 25px)"}
                        marginTop={"0px"}
                        padding={"0px"}
                        marginRight={"0.4em"}
                        color={"#FFFFFF"}
                        lineColor={"#000000"}
                        fontFamily={"Futura"}
                        text={"EXPLORE PROGRAM"}
                        showProgressionBar={false}
                      />
                    </button>
                  </div>
                  <div style={{display: "flex", justifyContent: "center", marginTop: "3vh", pointerEvents: "none"}}>
                    <svg onClick={() => this.superiorResultsRef.scrollIntoView({behavior: "smooth"})} style={{pointerEvents: "all", cursor: "pointer", height: "min(12vw, 70px)", width: "min(12vw, 70px)"}} className="loading-pulse-animation-down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1" x="0px" y="0px"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g fill="#000000"><path d="M20,27.4142136 L32.7071068,14.7071068 L31.2928932,13.2928932 L20,24.5799999 L8.70710678,13.2928932 L7.29289322,14.7071068 L20,27.4142136 Z"></path></g></g></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{position: "fixed", top: 0, width: innerWidth, backdropFilter: heroBackdropFilter, WebkitBackdropFilter: heroBackdropFilter, height: "100%", display: "flex", backgroundColor: heroOverlayColor, justifyContent: "center", zIndex: -1, pointerEvents: "none"}}>
          </div>
        </div>
        <div style={{paddingTop: (certificationCodeDetailsRequest.state === REQUEST_SUCCESS && !discountCodeExpirationMetadata.is_expired) ? 60 : undefined, textAlign: "left", width: "100vw"}} ref={elem => this.superiorResultsRef = elem}>
          {(certificationCodeDetailsRequest.state === REQUEST_SUCCESS && !discountCodeExpirationMetadata.is_expired) && (
            <div style={{padding: "5vh 0px", backgroundColor: "#000000", display: "flex", justifyContent: "center"}}>
              <div style={{display: "inline-block", color: "#FFFFFF", width: "85%", maxWidth: 700}}>
                <div style={{fontFamily: "Futura", fontSize: "min(6.5vw, 30px)"}}>
                  {`${certificationCodeDetailsRequest.result.first_name}, your unique code is:`}
                </div>
                <button
                  onClick={() => {
                    this.setState({codeIsCopied: true})
                    navigator.clipboard.writeText(certificationCodeDetailsRequest.result.unique_code)
                    setTimeout(() => {
                      this.setState({codeIsCopied: false})
                    }, 1000)
                  }}
                  style={{border: "none", backgroundColor: "#FFFFFF", margin: "2vh 0px", padding: "12px 18px", display: "flex", alignItems: "center", justifyContent: "center"}}
                >
                  <span style={{fontFamily: "Futura Medium", marginRight: "0.2em", fontSize: "min(8vw, 40px)", color: codeIsCopied ? "#555" : "#000000"}}>
                    {codeIsCopied ? "COPIED CODE" : `${certificationCodeDetailsRequest.result.unique_code.toUpperCase()}`}
                  </span>
                  <CopySvg
                    color={codeIsCopied ? "#555" : "#000000"}
                    size={"min(8vw, 40px)"}
                  />
                </button>
                <div style={{fontFamily: "Futura", fontSize: "min(6.5vw, 30px)"}}>
                  {`Redeem it at checkout for $${certificationCodeDetailsRequest.result.amount_off_usd_cents / 100} USD off. It expires in:`}
                </div>
                <div style={{fontFamily: "Futura Medium", marginTop: "2vh"}}>
                  <Countdown
                    date={discountCodeExpirationMetadata.expiration_date}
                    renderer={({days, hours, minutes, seconds, completed }) => {
                      // Render a countdown
                      return (
                        <span style={{fontFamily: "Futura Medium", fontSize: "min(8.5vw, 40px)", textAlign: "center"}}>{completed ? "JUNE 1ST" : `${days}D ${hours}H ${minutes}M ${seconds}S`}</span>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div style={{color: "black", padding: "calc(60px + 5vh) 0px", backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div>
                <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                  {"ADD NERVOUS SYSTEM FITNESS TO YOUR BUSINESS"}
                </div>
                <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  {`Master nervous system regulation, somatic release exercises, and trauma-informed witnessing, and become a Certified Trainer with our:`}
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura",fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Nervous System Certification Course"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Nervous System Trainer Tech + Toolkit"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"Done-For-You NeuroFit Business Suite, and"}
                  </div>
                </div>
                <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                  <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                    {"•"}
                  </div>
                  <div style={{fontFamily: "Futura", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                    {"3 months of ongoing support, Live Coaching and Trainer Community"}
                  </div>
                </div>
                <div style={{marginTop: "2vh", fontSize: "min(6vw, 25px)", textAlign: "left", fontFamily: "Futura"}}>
                  <span>{`Join hundreds of health coaches, therapists, doctors, healers, military nurses, and fitness trainers using our nervous system approach + tech with clients.`}</span>
                </div>
                <div style={{width: "100%", padding: "6vh 0vh", backgroundColor: "transparent"}}>
                  <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-around"}}>
                    <img src="/images/well-and-good-laurel-black.webp" style={{width: "86%", maxWidth: 350}} loading="lazy" alt="NeuroFit eased my stress in ways meditation never has -Well and Good" />
                  </div>
                  <div style={{display: "flex", marginTop: "3vh", width: "100%", alignItems: "center", justifyContent: "space-around"}}>
                    <img src="/images/body-and-soul-laurel-black.webp" style={{width: "100%", maxWidth: 400}} loading="lazy" alt="I finally found something to calm my stressed out nervous system -Body and Soul" />
                  </div>
                </div>
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", backgroundColor: "#FFFFFF"}}>
                  <div style={{width: "100%", maxWidth: 700}}>
                    <div style={{display: "flex", justifyContent: "center", marginTop: "2vh"}}>
                      <button
                        style={{display: "flex", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: 10, color: "#ffffff", backgroundColor: "#000000", padding: "12px 15px", border: "none"}}
                        onClick={() => {
                          recordPixelEventIfAvailable("Lead")
                          recordFunnelEventResultGA(GA_LABEL_CLICKED_CERTIFICATION_CHECKOUT_BUTTON, {location: "accelerate_growth"})
                          this.checkoutRef.scrollIntoView({behavior: "smooth"})
                        }}
                      >
                        <LoadingLogo
                          show={true}
                          hideAnimation={true}
                          hideLogo
                          responsiveSize={"min(6vw, 25px)"}
                          marginTop={"0px"}
                          padding={"0px"}
                          marginRight={"0.4em"}
                          color={"#FFFFFF"}
                          lineColor={"#000000"}
                          fontFamily={"Futura"}
                          text={"ENROLL NOW"}
                          showProgressionBar={false}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{padding: "calc(60px + 5vh) 0px", textAlign: "left", borderTop: "2px #000000 solid", width: "100vw", backgroundColor: "#EDEEF2"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                {"DRIVEN BY EMBODIED HEALTH EXPERTS. BACKED BY DATA."}
              </div>
              {isNative ? (
                <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  <span>{"The NeuroFit Method is grounded in neuroscience, and validated by tens of thousands of sessions on our nervous system app."}</span>
                </div>
              ) : (
                <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                  <span>
                    {"The NeuroFit Method is grounded in neuroscience, and validated by tens of thousands of sessions on our "}
                  </span>
                  <a href={NeuroFitUniversalAppStoreLink} style={{color: "#000000", fontFamily: "Futura"}} target="_blank" rel="noopener noreferrer nofollow">
                    {"nervous system app"}
                  </a>
                  <span>
                    <span>{"."}</span>
                  </span>
                </div>
              )}
              <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                <span>{"In just 5 minutes of NeuroFit somatic training, 95% of our members report relief from real-time stress and dysregulation:"}</span>
              </div>
              <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                <div style={{width: "100%", maxWidth: 450}}>
                  <LineGraph
                    dataIsAvailable
                    title={`REAL-TIME STRESS RELIEF`}
                    data={{
                      labels: ["0", "1", "2", "3"],
                      series: [[0, 88.8, 95, 98.6]],
                    }}
                    labelInterpolationFnc={(val) => `${val}%`}
                    highYValue={100}
                    hideLogo
                    xAxisTitle={"Exercises Completed (2-3 mins. each)"}
                  />
                </div>
              </div>
              <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                <span>{"With our data-driven approach, you'll know the most effective somatic exercises and nervous system practices for any client-- ensuring superior results at NeuroFit speed."}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", padding: "calc(60px + 5vh) 0px", borderTop: "2px #000000 solid", backgroundColor: "#FFFFFF"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", textAlign: "left"}}>
                {"WHAT YOU GET"}
              </div>

              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "4vh", textAlign: "left"}}>
                {"I. NERVOUS SYSTEM FITNESS COURSE"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "left"}}>
                {`VALUED AT $1,000 USD`}
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh auto"}}>
                {hasScrolled && (
                  <img src="/images/certification-course.webp" loading="lazy" style={{width: "100%", maxWidth: 400}} alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
                )}
              </div>
              <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Detailed Nervous System Course. Lays out our practical + data-driven methodology which includes:`}
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"A deep-dive into the NeuroFit Method - a new and novel approach to nervous system regulation"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"How to facilitate client breakthroughs with somatic exercises, in a trauma-informed manner"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"How to become fully embodied and regulated as a burnout + nervous system expert"}
                </div>
              </div>
              <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`A detailed course outline is provided below in the FAQs.`}
              </div>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "4vh", textAlign: "left"}}>
                {"II. TRAINER TECH + TOOLKIT"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "left"}}>
                {`VALUED AT $1,000 USD`}
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh auto"}}>
                {hasScrolled && (
                  <img src="/images/certification-trainer-tech-toolkit.webp" loading="lazy" style={{width: "100%", maxWidth: 400}} alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
                )}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "left"}}>
                {"TRAINER DASHBOARD"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Optimize your clients' results in real-time with data-driven insights.`}
              </div>
              <div style={{fontFamily: "Futura", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "left"}}>
                {"SOMATIC EXERCISE MANUAL"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Pinpoint the best somatic exercise for any client, in any nervous system state.`}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "4vh", textAlign: "left"}}>
                {"III. ONGOING TRAINER SUPPORT"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "left"}}>
                {`VALUED AT $1,000 USD`}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "left"}}>
                {"LIVE TRAININGS WITH OUR HEAD TRAINER"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Get hands-on support with monthly live trainings covering:`}
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Advanced support for facilitating somatic exercises"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Embodied business training in sales & marketing"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Real-time Q&A, to ensure a seamless experience for your clients"}
                </div>
              </div>
              <div style={{fontFamily: "Futura", marginTop: "4vh", fontSize: "min(7.5vw, 32px)", textAlign: "left"}}>
                {"TRAINER COMMUNITY"}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Light", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Connect with NeuroFit trainers and polish your skills inside our Certified Trainer Community.`}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "4vh", textAlign: "left"}}>
                {"IV. PROFITABLE CHALLENGE FORMULA"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "left"}}>
                {`VALUED AT $1,000 USD`}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`A validated 7-figure template for running online challenges as a health professional.`}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginTop: "4vh", textAlign: "left"}}>
                {"V. DONE-FOR-YOU BUSINESS SUITE"}
              </div>
              <div style={{fontFamily: "Futura", paddingBottom: "1vh", marginTop: "1vh", fontSize: "min(6vw, 26px)", color: "#777777", textAlign: "left"}}>
                {`VALUED AT $1,000 USD`}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Tried-and-tested tools, resources and swipe files designed to help you launch your nervous system fitness offer in 14-days or less.`}
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Skip the guesswork (and wasted time) developing your new offer with our full business suite of resources, including:`}
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Your “Business Audit” Workbook: quickly identify how your nervous system is affecting your business (and what to do about it)"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Step-by-Step of how to a build a Nervous System Fitness offer into your current business"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"How to host a successful nervous system fitness workshop or 1:1 session that your clients rave about"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Step-by-step process of building a successful funnel for your new offer"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"How to grow your authority and build your brand with social media"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"The “Energetics of Business Success” training"}
                </div>
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`Swipe Files Include:`}
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Your 6-week nervous system fitness program"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"Training Webinar Template (PDF download)"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"PDF copies of high-converting Optin Page + Sales Page"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"10+ email templates you can plug into your business"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"20+ Instagram templates that you can use immediately"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"30+ content headlines to spark your inspiration"}
                </div>
              </div>
              <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0vh 2vh 1vh 2vh"}}>
                <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(7.5vw, 32px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
                  {"•"}
                </div>
                <div style={{fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", lineHeight: "min(7.5vw, 32px)", textAlign: "left"}}>
                  {"10+ of the best automation tools to streamline your business"}
                </div>
              </div>
              <div style={{marginTop: 10, fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {`This Business Suite is designed help you grow your business, get your clients amazing results, and spend less time doing the boring stuff in the background.`}
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid"}} ref={elem => this.checkoutRef = elem}>
          <div style={{width: "100%"}}>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 500}}>
                <div style={{fontFamily: "Futura", fontSize: "min(8.5vw, 40px)", marginBottom: "2vh", textAlign: "left"}}>
                  {"ENROLL NOW"}
                </div>
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div style={{width: "85%", maxWidth: 500}}>
                {hasScrolled && (
                  <StripeCheckoutForm
                    isNative={isNative}
                    onShowSuccessMessage={(successMessage) => {}}
                    onShowErrorMessage={(errorMessage) => {}}
                    onApplyPromotionCode={promotionCode => {}}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", padding: `calc(60px + 5vh) 0px`, backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                {"Join Hundreds Of Embodied Health Professionals"}
              </div>
              <div style={{margin: "max(3vh, 20px) 0px", fontFamily: "Futura Light", fontSize: "min(6vw, 26px)", textAlign: "left"}}>
                {"NeuroFit's community of Certified Trainers come from many different backgrounds. Testimonials are below:"}
              </div>
            </div>
          </div>
          <TrainerTestimonials innerWidth={innerWidth} />
        </div>
        <div style={{backgroundColor: "#000000", display: "flex", justifyContent: "center", borderTop: "2px #000000 solid"}}>
          <div style={{width: "85%", maxWidth: 450, margin: "5vh 0px", color: "#FFFFFF", fontSize: "min(6vw, 26px)", textAlign: "center"}}>
            <div style={{fontFamily: "Futura Light", fontStyle: "italic"}}>{"Learn more about how you can get fast + superior client results:"}</div>
            <div
              onClick={() => {
                if (isNative) {
                  (!!onClickNativeVslLink) && onClickNativeVslLink()
                } else {
                  window.open("/vsl", "_blank", "noopener noreferrer")
                }
              }}
              style={{fontFamily: "Futura Medium", display: "flex", width: "100%", alignItems: "center", justifyContent: "center", marginTop: "1vh", cursor: "pointer", textDecoration: "underline", textUnderlineOffset: 2}}
            >
              <ExternalLinkSvg size={"min(4.5vw, 18px)"} color={"#FFFFFF"} />
              <span style={{marginLeft: "0.25em"}}>{"WATCH THE FREE TRAINING"}</span>
            </div>
          </div>
        </div>
        <div style={{textAlign: "left", width: "100vw", paddingTop: `calc(60px + 5vh)`, backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                {"Nervous System Course Outline"}
              </div>
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              {CourseOutlineItems.map(f => (
                <FaqSection
                  key={f.key}
                  question={f.question}
                  answer={f.answer}
                  showAnswer={showCourseDetail[f.key]}
                  onToggleShowAnswer={() => this.setState({showCourseDetail: {...showCourseDetail, [f.key]: (!showCourseDetail[f.key])}})}
                  marginTop={"2vh"}
                />
              ))}
            </div>
          </div>
          <div style={{color: "black", width: "100%", display: "flex", paddingTop: "calc(60px + 5vh)", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "left", fontFamily: "Futura", marginRight: "0.3em", textTransform: "uppercase"}}>
                {"Frequently Asked Questions"}
              </div>
            </div>
          </div>
          <TrainerFAQs />
          <div style={{padding: 15, paddingBottom: 15 + safeAreaInsets.bottom, backgroundColor: "transparent", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
            <div style={{display: "inline-block"}}>
              <span style={{color: "#c1c4d2"}}>
                {"© NEUROFIT 2023"}
              </span>
              <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                {"•"}
              </span>
              <span style={{color: "#c1c4d2"}}>
                {"ALL RIGHTS RESERVED"}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TrainerCertificationDetails